import { ReactNode, createContext, useContext, useState } from "react";
import { Grid, Box, Typography, useTheme, Button } from "@mui/material";
import { isUserAuthenticated } from "../Authentication/Auth";
import { usePaths } from "../Paths";
import { useNavigate } from "react-router-dom";

interface TitleBarContextProps {
  isTitleBarHidden: boolean;
  setIsTitleBarHidden: (isHidden: boolean) => void;
  titleBarTitle: string;
  setTitleBarTitle: (newTitle: string) => void;
  trailingComponents?: ReactNode;
  setTrailingTitleBarComponents: (components?: ReactNode) => void;
}

// Create the context with a default value
export const TitleBarContext = createContext<TitleBarContextProps>({
  isTitleBarHidden: false,
  setIsTitleBarHidden: () => {},
  titleBarTitle: "Default Title",
  setTitleBarTitle: () => {},
  trailingComponents: undefined,
  setTrailingTitleBarComponents: (components?: ReactNode) => {},
});

export const AppTitleBar = (props: { children: ReactNode }) => {
  const [titleBarTitle, setTitleBarTitle] = useState("My App Title"); // State for title
  const [isTitleBarHidden, setIsTitleBarHidden] = useState<boolean>(false);
  const [trailingComponents, setTrailingTitleBarComponents] = useState<
    ReactNode | undefined
  >();

  return (
    <TitleBarContext.Provider
      value={{
        isTitleBarHidden,
        setIsTitleBarHidden,
        titleBarTitle,
        setTitleBarTitle,
        trailingComponents,
        setTrailingTitleBarComponents,
      }}
    >
      <Grid container direction={"column"} width={"100%"}>
        <TitleBarContent
          isHidden={isTitleBarHidden}
          title={titleBarTitle}
          trailingComponents={trailingComponents}
        />
        {!isTitleBarHidden && <Box sx={{ height: "50px" }} />}
        <Grid item>{props.children}</Grid>
      </Grid>
    </TitleBarContext.Provider>
  );
};

interface TitleBarContentsProps {
  isHidden: boolean;
  title: string;
  trailingComponents: ReactNode | undefined;
}
const TitleBarContent = (props: TitleBarContentsProps) => {
  const theme = useTheme();
  if (props.isHidden) {
    return <div></div>;
  }
  return (
    <Grid
      item
      container
      direction={"row"}
      spacing={0}
      paddingX={1}
      paddingTop={0}
      paddingBottom={0}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{ height: "50px", position: "fixed", zIndex: "2" }}
      style={{
        color: theme.palette.text.primary,
        backdropFilter: "blur(10px)",
        WebkitBackdropFilter: "blur(10px)",
        backgroundColor: "rgba(255,255,255,0.65)",
      }}
    >
      <LeadingContent />
      {/* Keeping this out of here until I can figure out how to center it. <Grid item>
				<CenterContent title={props.title.toUpperCase()} />
			</Grid> */}
      {isUserAuthenticated() && props.trailingComponents && (
        <Grid item>
          <TrailingContent>{props.trailingComponents}</TrailingContent>
        </Grid>
      )}
    </Grid>
  );
};

const LeadingContent = (props: {}) => {
  const paths = usePaths();

  const handleIconClicked = () => {
    paths.navigateTo(paths.items);
  };

  return (
    <Grid item>
      <Grid container item direction="column" alignItems={"bottom"}>
        <Button onClick={handleIconClicked}>
          <img height="30" src="/EaselIconFull.svg" alt="Description" />
        </Button>
      </Grid>
    </Grid>
  );
};

const CenterContent = (props: { title: string }) => {
  const theme = useTheme();
  return (
    <Grid container direction="row" alignContent={"start"}>
      <Grid item>
        <Typography variant="h1" sx={{ color: theme.palette.text.secondary }}>
          {props.title}
        </Typography>
      </Grid>
    </Grid>
  );
};

const TrailingContent = (props: { children?: ReactNode }) => {
  const navigate = usePaths();

  const onClickNewItem = () => {
    navigate.navigateTo(navigate.createItem);
  };

  const onClickListItems = () => {
    navigate.navigateTo(navigate.items);
  };
  return (
    <Grid
      container
      direction="row"
      alignContent={"end"}
      alignItems={"center"}
      spacing={1}
    >
      {/* <Grid item>
        <Button variant="link" onClick={onClickNewItem}>
          ADD
        </Button>
      </Grid>
      <Grid item>
        <Button variant="link" onClick={onClickListItems}>
          ITEMS
        </Button>
      </Grid> */}
      <Grid item>{props.children}</Grid>
    </Grid>
  );
};
