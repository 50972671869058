import { SignUpConfirmForm } from './SignUpConfirmForm';
import { Grid, Typography, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { usePaths } from '../Paths';

export const SignUpConfirmPage = (props: {}) => {
	const paths = usePaths();
	const [isConfirming, setIsConfirming] = useState<boolean>(false);

	const handleIsConfirming = () => {
		setIsConfirming(true);
	};

	const handleDidConfirm = (didSignIn: boolean) => {
		setIsConfirming(false);
		if (didSignIn) {
			paths.navigateTo(paths.userProfile);
		} else {
			paths.navigateTo(paths.signIn);
		}
	};

	const handleConfirmFailed = () => {
		setIsConfirming(false);
	};
	return (
		<Grid container direction={'column'} alignItems={'center'} padding={1}>
			<Grid item className="OutlinedContainer">
				<SignUpConfirmForm
					handleIsConfirming={handleIsConfirming}
					handleDidConfirm={handleDidConfirm}
					handleConfirmFailed={handleConfirmFailed}
				/>
			</Grid>
		</Grid>
	);
};

const ProgressView = (props: {}) => {
	return (
		<Grid
			container
			rowSpacing={1}
			alignItems={'center'}
			direction={'column'}
			padding={1}>
			<Grid item>
				<Typography variant="h3">Confirming your account...</Typography>
			</Grid>
			<Grid item>
				<CircularProgress />
			</Grid>
		</Grid>
	);
};
