import React, { useEffect, useState } from "react";
import { Theme, Box, Grid, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export interface DropDownActionItem {
  id: string;
  title: string;
  navigationDestination?: string;
  isSeparator?: boolean;
}

type DropDownProps = {
  items: DropDownActionItem[];
  dropDownActive: boolean;
  toggleDropDown: Function;
  itemSelection: (item: DropDownActionItem) => void;
};

export const DropDownMenu = ({
  items,
  dropDownActive,
  itemSelection,
}: DropDownProps): JSX.Element => {
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const theme = useTheme();
  useEffect(() => {
    setShowDropDown(dropDownActive);
  }, []);
  /**
   * Handle passing the city name
   * back to the parent component
   *
   * @param city  The selected city
   */
  const onClickHandler = (item: DropDownActionItem): void => {
    itemSelection(item);
  };

  useEffect(() => {
    setShowDropDown(showDropDown);
  }, [showDropDown]);

  return (
    <Grid
      direction="row"
      className={showDropDown ? "dropdown" : "dropdown active"}
      sx={{ background: theme.palette.background.default }}
    >
      {items.map((item: DropDownActionItem, index: number): JSX.Element => {
        if (item.isSeparator === true) {
          return <SepartorItem item={item} />;
        } else {
          return <SingleItem item={item} onClick={onClickHandler} />;
        }
      })}
    </Grid>
  );
};

const SepartorItem = (props: { item: DropDownActionItem }) => {
  const theme = useTheme();
  return (
    <Grid
      item
      container
      direction="column"
      sx={{ width: "200px", height: "8px" }}
      justifyContent={"center"}
      alignContent={"left"}
    >
      <Grid
        item
        sx={{
          width: "100%",
          height: "100%",
          background: theme.palette.primary.main,
        }}
      ></Grid>
    </Grid>
  );
};

const SingleItem = (props: {
  item: DropDownActionItem;
  onClick: (item: DropDownActionItem) => void;
}) => {
  const theme = useTheme();
  const handleClick = () => {
    props.onClick(props.item);
  };
  return (
    <Grid
      item
      container
      direction="column"
      sx={{ width: "200px", height: "60px" }}
      justifyContent={"center"}
      alignContent={"left"}
    >
      <Grid item sx={{ width: "100%", height: "100%" }}>
        <Button
          variant="text"
          onClick={handleClick}
          sx={{
            paddingLeft: "23px",
            textAlign: "left",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography
            sx={{ width: "200px", color: theme.palette.text.primary }}
            variant="subtitle2"
          >
            {props.item.title}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};
