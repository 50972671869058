import { useContext, useState, useEffect } from "react";
import styles from "./ListItems.module.css";
import { AssetListContext } from "../../../CommonComponents/AssetListContext";
import { AppError } from "../../../API/API";
import { Dots } from "react-activity";
import {
  Item,
  ItemAsset,
  ListItemsResponse,
  listItems,
} from "../../../DataProviders/ItemAPI";
interface AssetGridProps {
  profileHandle?: string;
  isLoading: boolean;
  addNewItem: () => void;
  viewItem: (item: Item) => void;
  selectedItemIDs?: string[];
  onError: (error: AppError) => void;
}
export const AssetGrid = (props: AssetGridProps) => {
  const { listState, setListState } = useContext(AssetListContext);
  interface LoadedImages {
    [index: number]: boolean;
  }
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadedImages, setLoadedImages] = useState<LoadedImages>({});
  const [isLoadingNextPage, setIsLoadingNextPage] = useState<boolean>(false);
  const [items, setItems] = useState<Item[]>([]);
  const [allItemsFetched, setAllItemsFetched] = useState<boolean>(false);

  const handleNavigateAway = () => {
    setListState((prevState) => ({
      ...prevState,
      data: items,
    }));
  };

  useEffect(() => {
    if (listState.data.length > 0) {
      setItems(listState.data);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      const handle = props.profileHandle?.toLowerCase();
      fetchItems(undefined, handle).then((items) => {
        setIsLoading(false);
        setItems(items);
      });
    }
  }, []);

  const fetchItems = (
    lastItem?: Item,
    profileHandle?: string
  ): Promise<Item[]> => {
    const handle = profileHandle?.toLowerCase();
    console.log("Handle is " + handle);
    return listItems(lastItem, handle)
      .then((response: ListItemsResponse) => {
        setAllItemsFetched(response.endOfList);
        return filterForPublished(response.items);
      })
      .catch((error: AppError) => {
        props.onError(error);
        return [];
      });
  };

  const filterForPublished = (items: Item[]): Item[] => {
    items.map((item) => {
      console.log("Status = " + item.itemState);
      return item;
    });
    return items.filter((item) => item.itemState === "PUBLISHED");
  };

  const loadNextPageOfItems = () => {
    setIsLoadingNextPage(true);
    const lastItem = items[items.length - 1];
    fetchItems(lastItem, props.profileHandle).then((newItems: Item[]) => {
      setIsLoadingNextPage(false);
      const updatedItems = items.concat(newItems);
      setItems(updatedItems);
    });
  };
  //For fade-in animation
  const handleImageLoad = (index: number) => {
    setLoadedImages((prev) => ({ ...prev, [index]: true }));
  };

  const onItemTapped = (item: Item) => {
    handleNavigateAway();
    props.viewItem(item);
  };

  if (isLoading) {
    return (
      <div className={styles.loadingIndicator}>
        <Dots />
      </div>
    );
  }

  const assetForItem = (item: Item): ItemAsset | undefined => {
    if (item.assets && item.assets.length > 0) {
      return item.assets[0];
    }
  };

  const itemIsSelected = (item: Item) => {
    return props.selectedItemIDs?.indexOf(item.id ?? "") !== -1;
  };

  return (
    <div className={styles.assetGridContainer}>
      {items.map((item, index) => (
        <button className={styles.item} onClick={() => onItemTapped(item)}>
          <img
            className={
              itemIsSelected(item)
                ? styles.itemContentSelected
                : styles.itemContent
            }
            src={assetForItem(item)?.thumbnailURL ?? ""}
            alt={item.title}
            onLoad={() => handleImageLoad(index)}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              cursor: "pointer",
            }}
          />
        </button>
      ))}
    </div>
  );
};
interface LoadMoreButtonProps {
  isLoading: boolean;
  onClick: () => void;
}

const LoadMoreButton = (props: LoadMoreButtonProps) => {
  if (props.isLoading) {
    return (
      <div>
        <button type="button" onClick={props.onClick}>
          LOADING
        </button>
      </div>
      // <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={-1}>
      //   <Button className="asset-grid-button" onClick={props.onClick}>
      //     LOADING
      //   </Button>
      // </Grid>
    );
  } else {
    return (
      <div>
        <button type="button" onClick={props.onClick}>
          MORE
        </button>
      </div>
    );
  }
};
