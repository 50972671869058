import FileResizer from 'react-image-file-resizer';

export const resizeImageFile = (
	size: { width: number; height: number },
	file: File
): Promise<File> => {
	return new Promise((resolve) => {
		resizedImage(size, file, async (value) => {
			const base64 = await fetch(value);
			const blob = await base64.blob();
			const newFile = new File([blob], file.name, {
				type: blob.type,
				lastModified: new Date().getTime(),
			});
			resolve(newFile);
		});
	});
};

export const resizedImage = (
	size: { width: number; height: number },
	imageFile: Blob,
	callback: (value: any) => void
) => {
	try {
		FileResizer.imageFileResizer(
			imageFile,
			size.width,
			size.height,
			'JPEG',
			100,
			0,
			callback
		);
	} catch (err) {
		console.log(err);
	}
};
