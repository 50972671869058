import { createTheme } from '@mui/material';

const hex_BACKGROUND = '#FFFFFF';
const hex_BRAND_PRIMARY = '#000000';
const hex_BRAND_SECONDARY = '#B90042';
const hex_BACKGROUND_CONTRAST = '#000000';
const hex_BACKGROUND_PLACEHOLDER = '#F1F1F1';
const hex_BACKGROUND_HIGHLIGHTED = '#FAFAFA';
const hex_DIVIDER = '#E8E8E8';
const hex_OUTLINE = '#F6F6F6';
const hex_OUTLINE_HIGHLIGHTED = '#000000';

//Custom styles are uppercased.
declare module '@mui/material/styles' {
	interface PaletteOptions {
		BRAND_PRIMARY?: {
			main: string;
		};
		BRAND_SECONDARY?: {
			main: string;
		};
		BACKGROUND_CONTRAST?: {
			main: string;
		};
		BACKGROUND_PLACEHOLDER?: {
			main: string;
		};
		BACKGROUND_HIGHLIGHTED?: {
			main: string;
		};
		OUTLINE?: {
			main: string;
		};
		OUTLINE_HIGHLIGHTED?: {
			main: string;
		};
	}
	interface Palette {
		BRAND_PRIMARY?: {
			main: string;
		};
		BRAND_SECONDARY?: {
			main: string;
		};
		BACKGROUND_CONTRAST?: {
			main: string;
		};
		BACKGROUND_PLACEHOLDER?: {
			main: string;
		};
		BACKGROUND_HIGHLIGHTED?: {
			main: string;
		};
		OUTLINE?: {
			main: string;
		};
		OUTLINE_HIGHLIGHTED?: {
			main: string;
		};
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		link: true;
	}
}

export const MainTheme = createTheme({
	spacing: 16,
	palette: {
		primary: {
			main: hex_BRAND_PRIMARY,
		},
		error: {
			main: '#BF3030',
		},
		warning: {
			main: '#EFA73D',
		},
		info: {
			main: '#559139',
		},
		success: {
			main: '#559139',
		},
		text: {
			primary: '#000000',
			secondary: '#5F5F5F',
			disabled: '#B3B3B3',
		},
		background: {
			default: hex_BACKGROUND,
		},
		divider: hex_DIVIDER,
		BRAND_PRIMARY: {
			main: hex_BRAND_PRIMARY,
		},
		BRAND_SECONDARY: {
			main: hex_BRAND_SECONDARY,
		},
		BACKGROUND_CONTRAST: {
			main: hex_BACKGROUND_CONTRAST,
		},
		BACKGROUND_PLACEHOLDER: {
			main: hex_BACKGROUND_PLACEHOLDER,
		},
		BACKGROUND_HIGHLIGHTED: {
			main: hex_BACKGROUND_HIGHLIGHTED,
		},
		OUTLINE: {
			main: hex_OUTLINE,
		},
		OUTLINE_HIGHLIGHTED: {
			main: hex_OUTLINE_HIGHLIGHTED,
		},
	},
	typography: {
		h1: {
			fontSize: '1rem',
			fontWeight: 600,
		},
		h2: {
			fontSize: '1rem',
			fontWeight: 500,
		},
		h3: {
			fontSize: '1.125rem',
			fontWeight: 400,
		},
		subtitle1: {
			fontSize: '1rem',
			fontWeight: 500,
		},
		subtitle2: {
			fontSize: '0.875rem',
			fontWeight: 500,
		},
		body1: {
			fontSize: '1rem',
			fontWeight: 400,
		},
		body2: {
			fontSize: '0.9rem',
			fontWeight: 300,
		},
		// Add other variants as needed
	},
	components: {
		MuiTextField: {
			styleOverrides: {
				root: {
					'& .MuiInputBase-root': {
						'&:hover': {
							backgroundColor: 'transparent',
						},
						'&.Mui-focused': {
							backgroundColor: hex_BACKGROUND_HIGHLIGHTED,
						},
					},
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							borderColor: hex_DIVIDER,
							borderRadius: '1px',
						},
						'&:hover fieldset': {
							borderColor: hex_BRAND_PRIMARY,
						},
						'&.Mui-focused fieldset': {
							borderColor: hex_BRAND_PRIMARY,
						},
						'&:hover': {
							backgroundColor: hex_BACKGROUND_HIGHLIGHTED,
						},
						'&.Mui-focused': {
							backgroundColor: 'transparent',
						},
					},
				},
			},
		},
		MuiButton: {
			variants: [
				{
					props: { variant: 'link' },
					style: {
						backgroundColor: hex_BACKGROUND,
						color: hex_BRAND_PRIMARY,
						padding: 0,
						textTransform: 'none',
						'&:hover': {
							background: hex_BACKGROUND_HIGHLIGHTED,
						},
					},
				},
			],
			styleOverrides: {
				root: {
					borderRadius: 4,
					boxShadow: 'none',
					'&:hover': {
						boxShadow: 'none', // Removes the shadow on hover
					},
				},
				contained: {
					// Your style overrides here
					backgroundColor: 'black',
					color: 'white',
					'&:hover': {
						backgroundColor: 'black',
					},
					// Add more styling as needed
				},
				outlined: {
					// Your style overrides here
					backgroundColor: hex_BACKGROUND,
					color: hex_BRAND_PRIMARY,
					'&:hover': {
						backgroundColor: 'black',
						color: hex_BACKGROUND,
					},
					// Add more styling as needed
				},
				containedSecondary: {
					// Your style overrides here
					backgroundColor: 'black',
					boxShadow: 'none',
					color: 'white',
					'&:hover': {
						backgroundColor: 'black',
					},
					// Add more styling as needed
				},
			},
		},
	},
});
