import { POST_JSONObjectToEndpoint, Endpoint, AppError } from '../API/API';
import { jwtDecode } from 'jwt-decode';
export {
	signUpUser,
	signInUser,
	signInAfterConfirmation,
	signOutUser,
	confirmUser,
	refreshAccessToken,
	validateEmail,
	validatePassword,
	isUserAuthenticated,
	retrieveAccessToken,
	userIDFromToken,
	AuthError,
};

var cachedEmail: string | undefined;
var cachedPassword: string | undefined;

const signUpUser = async (email: string, password: string) => {
	const params = { email: email, password: password };
	return POST_JSONObjectToEndpoint(Endpoint.signUp, params)
		.then((json) => {
			cachedEmail = email;
			cachedPassword = password;
		})
		.catch((error) => {
			throw new AppError(
				"Couldn't sign up with this email/password.",
				error.code
			);
		});
};

const signInUser = async (email: string, password: string) => {
	const params = { email: email, password: password };
	return POST_JSONObjectToEndpoint(Endpoint.signIn, params)
		.then((json) => {
			const accessToken = json.AccessToken;
			const refreshToken = json.RefreshToken;
			saveAccessToken(accessToken);
			saveRefreshToken(refreshToken);
		})
		.catch((error) => {
			if (error.statusCode == 422) {
				throw new AppError('Incorrect email or password.', 422);
			}
			throw new AppError('There was a problem signing in.', 401);
		});
};

const signOutUser = () => {
	deleteToken();
};

const refreshAccessToken = async () => {
	const refreshToken = retrieveRefreshToken();
	if (!refreshAccessToken) {
		throw new AppError('Unauthorized', 401);
	}
	const params = { refreshToken: refreshToken };
	return POST_JSONObjectToEndpoint(Endpoint.signIn, params)
		.then((json) => {
			const token = json.AccessToken;
			if (token) {
				saveAccessToken(token);
				return true;
			}
			return false;
		})
		.catch((error) => {
			throw new AppError(error.code, error.message);
		});
};

class AuthError extends Error {
	code: string;
	constructor(message: string, code: string) {
		super(message);
		this.code = code;
	}
}

const errorMessageFromError = (error: AuthError): string => {
	let generic = 'An error occured.  Please try again.';
	switch (error.code) {
		case 'UsernameExistsException':
			return generic;
		case 'InvalidPasswordException':
			return 'Invalid password.';
		case 'UserNotFoundException':
			return 'No user with that email exists.';
		case 'CodeMismatchException':
			return 'The verification code is incorrect, please check your email and try again.';
		case 'ExpiredCodeException':
			return 'This verification code has expired. Please contact support.';
		case 'NotAuthorizedException':
			return 'Incorrect username and/or password.';
		default:
			console.log('Failed to log in with error - ' + error);
			return 'An error occurred';
	}
};

const confirmUser = async (email: string, confirmationCode: string) => {
	const params = { email: email, confirmationCode: confirmationCode };
	return POST_JSONObjectToEndpoint(Endpoint.signUpConfirm, params).catch(
		(error) => {
			throw new AppError(error.messaage, error.statusCode);
		}
	);
};

const signInAfterConfirmation = async (): Promise<boolean> => {
	if (cachedEmail && cachedPassword) {
		return await signInUser(cachedEmail, cachedPassword)
			.then(() => {
				return true;
			})
			.catch(() => {
				return false;
			});
	} else {
		return false;
	}
};

const validateEmail = (email: string): boolean => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailRegex.test(email);
};

const validatePassword = (password: string): boolean => {
	/*
    Minimum 8 characters.
    Contains at least 1 number.
    Contains at least 1 special character.
    Contains at least 1 uppercase letter.
    Contains at least 1 lowercase letter.
    */
	const passwordRegex =
		/^(?=.*\d)(?=.*[!@#$;%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
	return passwordRegex.test(password);
};

const isUserAuthenticated = (): boolean => {
	return retrieveAccessToken() != null;
};

const userIDFromToken = (): string | undefined => {
	const token = retrieveAccessToken();
	if (token) {
		const id = getUserIdFromToken(token);
		return id;
	}
};

const saveAccessToken = (token: string) => {
	localStorage.setItem('accessToken', JSON.stringify(token));
};

const saveRefreshToken = (token: string) => {
	localStorage.setItem('refreshToken', JSON.stringify(token));
};

const retrieveAccessToken = (): string | null => {
	const savedToken = localStorage.getItem('accessToken');
	if (savedToken) {
		return JSON.parse(savedToken);
	}
	return null;
};

const retrieveRefreshToken = (): string | null => {
	const savedToken = localStorage.getItem('refreshToken');
	if (savedToken) {
		return JSON.parse(savedToken);
	}
	return null;
};

const deleteToken = () => {
	localStorage.removeItem('accessToken');
	localStorage.removeItem('refreshToken');
};

function getUserIdFromToken(token: string): string | undefined {
	try {
		const decodedToken = jwtDecode(token);
		console.log(decodedToken);

		// Accessing the user ID (sub)
		if (decodedToken.sub) {
			return decodedToken.sub;
		} else {
			console.log('User ID not found in the token.');
		}
	} catch (error) {
		console.error('Error decoding token:', error);
	}
}
