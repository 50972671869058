import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import {
  Item,
  ItemAsset,
  deleteItem as deleteItemViaAPI,
} from "../../../DataProviders/ItemAPI";
import { setAssetOnItem, removeAssetFromItem } from "./ItemUtils";
import { saveItem } from "../../../DataProviders/ItemAPI";
import { useCallback } from "react";
import {
  useItemAssetService,
  ItemAssetServiceState,
} from "../../../Hooks/useItemAssetService";
import { useDebounce } from "use-debounce";

interface ItemProviderProps {
  children: ReactNode;
  initialItem: Item;
}

export type ItemProviderState =
  | "isSavingDraft"
  | "isSavingCommit"
  | "ready"
  | "error"
  | "isDeleting"
  | "itemDeleted"; // Indicates that the item has been deleted and the provider should no longer be used - i.e. the user should be directed to the previous page.

export type ValidationError = "titleRequired" | "assetRequired";

interface ItemContextType {
  item: Item;
  setTitle: (title: string) => void;
  setDescription: (description: string) => void;
  setMedium: (medium: string) => void;
  setAsset: (asset: ItemAsset, index?: number) => void;
  setAssetOrder: (assets: ItemAsset[]) => void;
  addImageFileAsAsset: (
    file: File,
    onStateChange: (state: ItemAssetServiceState) => void,
    index?: number
  ) => Promise<void>;
  removeAsset: (asset: ItemAsset) => void;
  deleteItem: () => void;
  commit: () => void;
  revertChanges: () => void;
  validationErrors: ValidationError[] | undefined;
  clearValidationErrors: () => void;
  state: ItemProviderState;
  hasEdits: boolean;
}

const ItemContext = createContext<ItemContextType | undefined>(undefined);

const ItemProvider: React.FC<ItemProviderProps> = ({
  children,
  initialItem,
}) => {
  //This is used to revert back to when reverting changes.
  const [originalItem, setOriginalItem] = useState<Item | undefined>();

  // Setting this triggers a save.
  const [draftToSave, setDraftToSave] = useState<Item | undefined>();

  // Setting this updates UI elements.  Is set to item.edits
  const [edits, setEdits] = useState<Item>(initialItem);

  const [itemToCommit, setItemToCommit] = useState<Item | undefined>();

  const [state, setState] = useState<ItemProviderState>("ready");
  const assetService = useItemAssetService();
  const [hasEdits, setHasEdits] = useState<boolean>(false);
  const [debouncedDraft] = useDebounce(draftToSave, 0);
  const [validationErrors, setValidationErrors] = useState<
    ValidationError[] | undefined
  >();

  const updateEdits = useCallback(
    (fromItem: Item) => {
      const item = fromItem.edits;
      if (item) {
        item.id = fromItem.id;
        setEdits(item);
      }
    },
    [setEdits]
  );

  const unsafeSaveDraft = useCallback(
    (itemToSave: Item) => {
      saveItem(itemToSave, false)
        .then((savedItem) => {
          if (savedItem.edits) {
            updateEdits(savedItem);
          }
          setOriginalItem(savedItem);
        })
        .catch((_error) => {
          setState("error");
        });
    },
    [updateEdits]
  );

  useEffect(() => {
    if (debouncedDraft) {
      console.log("Saving draft...");
      unsafeSaveDraft(debouncedDraft);
    } else {
      //Unmounted
    }
  }, [debouncedDraft, unsafeSaveDraft]);

  useEffect(() => {
    if (initialItem.edits) {
      updateEdits(initialItem);
    } else {
      setEdits(initialItem);
    }
    setOriginalItem(initialItem);
  }, [updateEdits, setEdits, initialItem]);

  useEffect(() => {}, [edits]);

  useEffect(() => {
    if (itemToCommit) {
      saveItem(itemToCommit, true).then((item) => {
        setEdits(item);
        setOriginalItem(item);
        setItemToCommit(undefined);
      });
    }
  }, [itemToCommit]);

  const setTitle = useCallback(
    (title: string) => {
      if (title !== edits.title) {
        setHasEdits(true);
      }
      setEdits((prevItem) => ({ ...prevItem, title }));
    },
    [edits.title]
  );

  const setDescription = useCallback(
    (description: string) => {
      if (description !== edits.description) {
        setHasEdits(true);
      }
      setEdits((prevItem) => ({ ...prevItem, description }));
    },
    [edits.description]
  );

  const setMedium = useCallback(
    (medium: string) => {
      if (medium !== edits.medium) {
        setHasEdits(true);
      }
      setEdits((prevItem) => ({ ...prevItem, medium }));
    },
    [edits.medium]
  );

  const setAssetOrder = (assets: ItemAsset[]) => {
    setEdits((prevItem) => ({ ...prevItem, assets }));
    setHasEdits(true);
  };

  const addImageFileAsAsset = useCallback(
    async (
      file: File,
      onStateChange: (state: ItemAssetServiceState) => void,
      index?: number
    ) => {
      assetService.processImageFile(file, onStateChange).then((asset) => {
        // Usable locally until the signed URL has been created by backend on save.
        asset.fileURL = URL.createObjectURL(file);
        setAssetOnItem(edits, asset, index);
        setEdits(edits);
        setDraftToSave(edits);
      });
    },
    [edits, assetService]
  );

  const setAsset = useCallback(
    (asset: ItemAsset, index?: number) => {
      setAssetOnItem(edits, asset, index);
      setDraftToSave(edits);
    },
    [edits]
  );

  const removeAsset = useCallback(
    (asset: ItemAsset) => {
      removeAssetFromItem(edits, asset);
      setDraftToSave(edits);
    },
    [edits]
  );

  const validate = (item: Item): ValidationError[] | undefined => {
    var errors: ValidationError[] = [];
    if (item.title === undefined || item.title.length === 0) {
      errors.push("titleRequired");
    }
    if (item.assets === undefined || item.assets.length === 0) {
      errors.push("assetRequired");
    }
    console.log("Errors " + errors);
    return errors.length > 0 ? errors : undefined;
  };

  const clearValidationErrors = () => {
    setValidationErrors(undefined);
  };

  const commit = useCallback(() => {
    const errors = validate(edits);
    if (errors) {
      setValidationErrors(errors);
    } else {
      setValidationErrors(undefined);
      setItemToCommit(edits);
    }
  }, [edits]); // Removed edits from the dependency array

  const revertChanges = useCallback(() => {
    if (originalItem) {
      setValidationErrors(undefined);
      originalItem.edits = undefined;
      setItemToCommit(originalItem);
      setEdits(originalItem);
    }
  }, [originalItem]);

  const deleteItem = useCallback(() => {
    if (originalItem?.id) {
      setState("isDeleting");
      deleteItemViaAPI(originalItem.id)
        .then(() => {
          setState("itemDeleted");
        })
        .catch((_error) => {
          setState("error");
        });
    }
  }, [originalItem]);

  useEffect(() => {
    setHasEdits(originalItem?.edits !== undefined);
  }, [originalItem]);

  return (
    <ItemContext.Provider
      value={{
        item: edits,
        setTitle,
        setDescription,
        setMedium,
        setAsset,
        setAssetOrder,
        addImageFileAsAsset,
        removeAsset,
        commit,
        revertChanges,
        validationErrors,
        clearValidationErrors,
        state,
        hasEdits,
        deleteItem,
      }}
    >
      {children}
    </ItemContext.Provider>
  );
};

function useItem() {
  const context = useContext(ItemContext);
  if (!context) {
    throw new Error("useItem must be used within an ItemProvider");
  }
  return context;
}

export { ItemProvider, useItem };
