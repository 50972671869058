import { useState, useEffect, useContext } from "react";
import {
  Grid,
  TextField,
  Alert,
  FormControl,
  Button,
  IconButton,
  CircularProgress,
  Typography,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { ImportImageControl } from "../../CommonComponents/ImportImageControl";
import {
  UserProfile,
  updateUserProfile,
  getUserProfile,
  validateProfileHandle,
} from "../../DataProviders/UserDataProvider";
import { AppError } from "../../API/API";
import { useTheme } from "@mui/material/styles";
import { TitleBarContext } from "../../TitleBar/TitleBar";
import { UserAdminNavBarMenuButton } from "../../TitleBar/NavBarControls";
import { uploadImage, ImageUploadConfiguration } from "../../S3/S3Utility";
import {
  useItemAssetService,
  ItemAssetServiceState,
} from "../../Hooks/useItemAssetService";
import { ItemAsset } from "../../DataProviders/ItemAPI";
type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

export const UserProfilePage = (props: {}) => {
  const {
    setTitleBarTitle,
    setTrailingTitleBarComponents,
    setIsTitleBarHidden,
  } = useContext(TitleBarContext);
  const assetService = useItemAssetService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNewProfile, setIsNewProfile] = useState<boolean>(false);

  const [displayName, setDisplayName] = useState<string | undefined>();
  const [profileHandle, setProfileHandle] = useState<string | undefined>();
  const [isProfileHandleValid, setIsProfileHandleValid] = useState<
    boolean | undefined
  >();

  const [aboutMe, setAboutMe] = useState<string | undefined>();

  const [asset, setAsset] = useState<ItemAsset | undefined>();
  //Used as the key to save the image on the backend.
  //   const [imageKey, setImageKey] = useState<string | undefined>();
  //   //A pre-signed URL populated when fetching a Profile.
  //   const [imageURL, setImageURL] = useState<string | undefined>();
  //   //Image file populated when selecting a new image to upload.
  //   const [imageFile, setImageFile] = useState<File | undefined>();

  const [allInputsValid, setAllInputsValid] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [initialProfile, setInitialProfile] = useState<
    UserProfile | undefined
  >();

  useEffect(() => {
    setIsLoading(true);
    setIsTitleBarHidden(false);
    getUserProfile()
      .then((profile) => {
        setIsNewProfile(false);
        setInitialProfile(profile);
        setDisplayName(profile.displayName);
        setProfileHandle(profile.profileHandle);
        setAboutMe(profile.aboutMe);
        setAsset(profile.asset);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error instanceof AppError) {
          if (error.statusCode == 404) {
            setIsNewProfile(true);
          } else {
            setErrorMessage(error.message);
          }
        }
        setIsLoading(false);
      });
    setTrailingTitleBarComponents(<UserAdminNavBarMenuButton />);
  }, []);

  useEffect(() => {
    validateInputs();
    setErrorMessage(undefined);
  }, [displayName, aboutMe, profileHandle]);

  useEffect(() => {
    setTitleBarTitle(isNewProfile ? "Create your profile" : "Profile");
  }, [isNewProfile]);

  useEffect(() => {
    if (errorMessage) {
      setIsUpdating(false);
    }
  }, [errorMessage]);

  const fetchUserProfile = () => {};

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!allInputsValid) {
      setErrorMessage(
        "One or more of the input fields are not valid, please check them and try again."
      );
      return;
    }
    setIsUpdating(true);

    if (displayName && profileHandle && asset) {
      performUpload(displayName, profileHandle, aboutMe, asset);
    } else {
      setErrorMessage(
        "Some fields are invalid or empty, please edit and try again."
      );
    }
  };

  //   const submitWithImageUpload = (
  //     imageFile: File,
  //     displayName: string,
  //     profileHandle: string,
  //     aboutMe: string | undefined
  //   ) => {
  //     /**
  // 		 	resizedImageSize: { width: number; height: number };
  // 	thumbnailImageSize: { width: number; height: number };
  // 		 */
  //     const configuration: ImageUploadConfiguration = {
  //       resizedImageSize: { width: 400, height: 400 },
  //       thumbnailImageSize: { width: 100, height: 100 },
  //     };
  //     uploadImage(imageFile, configuration)
  //       .then((result) => {
  //         const key = result.imageKey;
  //         setImageFile(undefined);
  //         //Key will be in the form of something like <userID>/<imageID>.jpg.
  //         performUpload(displayName, profileHandle, aboutMe, key);
  //       })
  //       .catch((error) => {
  //         if (error instanceof AppError) {
  //           setErrorMessage(error.message);
  //         }
  //       });
  //   };

  const performUpload = (
    displayName: string,
    profileHandle: string,
    aboutMe: string | undefined,
    asset: ItemAsset
  ) => {
    setErrorMessage(undefined);
    const profile: UserProfile = {
      displayName: displayName ?? "",
      profileHandle: profileHandle ?? "",
      asset: asset,
      aboutMe: aboutMe ?? "",
    };
    updateUserProfile(profile)
      .then(() => {
        setIsNewProfile(false);
        setIsUpdating(false);
        setHasChanges(false);
      })
      .catch((error) => {
        setIsUpdating(false);
        if (error.statusCode === 409) {
          setErrorMessage(
            "'" +
              profileHandle +
              "'" +
              " is already taken as a profile nickname."
          );
        } else {
          setErrorMessage(error.message);
        }
      });
  };

  const validateInputs = () => {
    let isNameValid = displayName && displayName.length > 2;
    let isProfileHandleValid =
      profileHandle && validateProfileHandle(profileHandle);
    let isAboutMeValid = true; //No need to write anything about youself as a user.
    let isImageURLValid = true;
    if (
      isNameValid &&
      isAboutMeValid &&
      isImageURLValid &&
      isProfileHandleValid
    ) {
      setAllInputsValid(true);
      updateHasChanges(true);
    } else {
      setAllInputsValid(false);
      updateHasChanges(false);
    }
  };

  const updateHasChanges = (allInputsValid: boolean) => {
    if (allInputsValid) {
      if (initialProfile) {
        let changes =
          displayName !== initialProfile.displayName ||
          profileHandle !== initialProfile.profileHandle ||
          aboutMe !== initialProfile.aboutMe;
        setHasChanges(changes);
      } else {
        setHasChanges(true);
      }
    } else {
      setHasChanges(false);
    }
  };

  const onItemAssetServiceStateChange = (state: ItemAssetServiceState) => {
    console.log("ItemAssetServiceState: " + state);
  };

  const onImageSet = (file: File) => {
    assetService
      .processImageFile(file, onItemAssetServiceStateChange)
      .then((asset) => {
        setAsset(asset);
      });
  };

  const onDisplayNameChange = (event: ChangeEvent) => {
    setDisplayName(event.target.value);
  };

  const onAboutMeChange = (event: ChangeEvent) => {
    setAboutMe(event.target.value);
  };

  const onProfileHandleChange = (handle: string) => {
    const lowerCase = handle.toLowerCase();
    const isValid = validateProfileHandle(lowerCase);
    setIsProfileHandleValid(isValid);
    setProfileHandle(lowerCase);
  };

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <form onSubmit={handleSubmit} className="form-container">
      <FormControl fullWidth>
        <Grid
          container
          direction={"column"}
          padding={1}
          spacing={1}
          alignItems={"center"}
        >
          <Grid item>
            <UserProfileImage
              existingImageURL={asset?.thumbnailURL}
              onImageSet={onImageSet}
            />
          </Grid>
          <Grid item className="form-field">
            <TextField
              sx={{
                "& .MuiInputBase-input": {
                  textAlign: "center",
                },
              }}
              variant="outlined"
              onChange={onDisplayNameChange}
              fullWidth
              name="displayName"
              placeholder="Display Name"
              title="Display name"
              value={displayName}
            ></TextField>
          </Grid>
          <Grid item className="form-field">
            <TextField
              onChange={onAboutMeChange}
              fullWidth
              multiline
              rows={4}
              name="aboutMe"
              placeholder="About me"
              title="About me"
              value={aboutMe}
            ></TextField>
          </Grid>
          <ProfileHandleSection
            profileHandle={profileHandle}
            onProfileHandleChange={onProfileHandleChange}
            isProfileValid={isProfileHandleValid ?? true}
          />
          {errorMessage && (
            <Grid item>
              <Alert severity="error">{errorMessage}</Alert>
            </Grid>
          )}
          <SubmitButton isUpdating={isUpdating} hasChanges={hasChanges} />
        </Grid>
      </FormControl>
    </form>
  );
};

const NewProfileToolTip = () => {
  return (
    <Grid item container direction={"column"}>
      <Grid item>
        <Typography variant="h3">Set up your profile</Typography>
      </Grid>
    </Grid>
  );
};

interface ProfileHandleSectionProps {
  profileHandle: string | undefined;
  isProfileValid: boolean;
  onProfileHandleChange: (handle: string) => void;
}
const ProfileHandleSection = (props: ProfileHandleSectionProps) => {
  const onProfileHandleChange = (event: ChangeEvent) => {
    props.onProfileHandleChange(event.target.value);
  };
  const theme = useTheme();
  return (
    <Grid
      item
      container
      direction={"column"}
      width={"100%"}
      justifyContent="left"
      spacing={0.5}
    >
      <Grid item>
        <Typography variant="body2" align="left">
          Choose a nickname to link to your profile
        </Typography>
      </Grid>
      {!props.isProfileValid && (
        <Grid item>
          <Typography variant="body2" align="left">
            Alphanumeric characters only, between 3 and 12 characters
          </Typography>
        </Grid>
      )}
      <Grid
        item
        container
        direction={"row"}
        alignItems={"center"}
        spacing={0.2}
      >
        <Grid item>
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              fontSize: "1.2rem",
              fontWeight: 600,
            }}
          >
            easel.ink/
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            sx={{
              "& .MuiInputBase-input": {
                textAlign: "left",
              },
              fontSize: "1.2rem",
              fontWeight: 600,
            }}
            variant="outlined"
            onChange={onProfileHandleChange}
            name="profileHandle"
            placeholder="Profile handle"
            title="Profile handle"
            error={!props.isProfileValid}
            value={props.profileHandle}
          ></TextField>
        </Grid>
      </Grid>
    </Grid>
  );
};

const SubmitButton = (props: { isUpdating: boolean; hasChanges: boolean }) => {
  if (!props.hasChanges) {
    return <div></div>;
  } else {
    if (props.isUpdating) {
      return (
        <Grid item>
          <CircularProgress />
        </Grid>
      );
    } else {
      return (
        <Grid item>
          <Button type="submit">Save</Button>
        </Grid>
      );
    }
  }
};

const UserProfileImage = (props: {
  existingImageURL: string | undefined;
  onImageSet: (file: File) => void;
}) => {
  const [fileURL, setFileURL] = useState<string | undefined>();
  const theme = useTheme();

  useEffect(() => {
    if (props.existingImageURL) {
      setFileURL(props.existingImageURL);
    }
  }, [props.existingImageURL]);

  const onImageSet = (file: File) => {
    props.onImageSet(file);
    let url = URL.createObjectURL(file);
    setFileURL(url);
  };

  return (
    <ImportImageControl maximumImageSizeInKB={5000} onImageSet={onImageSet}>
      <div
        className="container"
        style={{
          borderRadius: "50%",
          width: "120px",
          height: "120px",
          objectFit: "cover",
          overflow: "hidden",
          borderWidth: "1px",
          borderColor: theme.palette.divider,
          borderStyle: "solid",
        }}
      >
        {fileURL && (
          <img
            src={fileURL}
            className="image"
            alt="Uploaded"
            onLoad={() => {
              // Release the object URL to free memory when the image is loaded
              URL.revokeObjectURL(fileURL);
            }}
          />
        )}
        <div
          className="changeableImage-overlay"
          style={{
            borderRadius: "50%",
          }}
        >
          <IconButton className="icon">
            <AddPhotoAlternateIcon className="icon"></AddPhotoAlternateIcon>
          </IconButton>
        </div>
      </div>
    </ImportImageControl>
  );
};

const LoadingView = () => {
  return (
    <Grid container direction={"column"} alignItems={"center"} padding={3}>
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};
