import { Button } from '@mui/material';
import { ReactNode, MouseEvent } from 'react';

export { ButtonContained, ButtonLink };

const ButtonContained = (props: {
	children: ReactNode;
	disabled?: boolean;
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
	const { disabled = false } = props;
	return (
		<Button
			variant="contained"
			sx={{ paddingInline: 2 }}
			disabled={disabled}
			onClick={props.onClick}>
			{props.children}
		</Button>
	);
};

const ButtonLink = (props: {
	children: ReactNode;
	disabled?: boolean;
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
	const { disabled = false } = props;
	return (
		<Button
			variant="text"
			sx={{ paddingInline: 2 }}
			disabled={disabled}
			onClick={props.onClick}>
			{props.children}
		</Button>
	);
};
