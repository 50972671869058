import { useNavigate } from "react-router-dom";

//Leaving the parameter empty in any path will result in the path 'pattern' being used - e.g. ":itemID".
export const usePaths = () => {
  const navigate = useNavigate();

  const home = "/";
  const userProfile = "/userProfile";
  const userProfileWithHandle = "/";
  const items = "/items/";
  const user = "/user/";
  const signUp = "/signup/";
  const signIn = "/signin/";
  const confirmEmail = "/confirm-email/";
  const createItem = "/edit/";
  const editItem = "/edit/";
  const viewItem = "/item/";
  const editGallery = "/gallery/";
  const createGallery = "/gallery/";

  const navigateTo = (path: string) => {
    navigate(path);
  };

  const pathMatches = (path1: string, path2: string): boolean => {
    const path1WithoutTrailingPath = path1.replace(/\/$/, "");
    const path2WithoutTrailingPath = path2.replace(/\/$/, "");
    return path1WithoutTrailingPath === path2WithoutTrailingPath;
  };
  return {
    navigateTo,
    pathMatches,
    home,
    userProfile,
    userProfileWithHandle,
    items,
    user,
    signUp,
    signIn,
    confirmEmail,
    createItem,
    editItem,
    viewItem,
    editGallery,
    createGallery,
  } as const;
};
