import { useState, useEffect } from "react";
import FileResizer from "react-image-file-resizer";
import {
  fetchPresignedUploadURL,
  uploadFileToAWSWithPresignedURL,
} from "../../../S3/S3Utility";
import {
  ItemAssetServiceState,
  useItemAssetService,
} from "../../../Hooks/useItemAssetService";

import { Grid, Box, useTheme, Button } from "@mui/material";
import { ItemAsset } from "../../../DataProviders/ItemAPI";
import { ImportImageControl } from "../../../CommonComponents/ImportImageControl";
import styles from "./ItemAssetView.module.css";
import baseStyles from "common.module.css";
import { useRef } from "react";

/** A component responsible for:
 * - Letting the user choose an image to upload.
 * - Fetching presigned URLs for the image and a thumbnail version of it to upload.
 * - Resizing the image to fit within a certain maximum.
 * - Resizing the image to a thumbnail version.
 * - Uploading both the image and the thumbnail to the URLs.
 */

interface ItemAssetProps {
  asset?: ItemAsset | undefined;
  fileURL?: string | undefined;
  isThumbnail: boolean;
  onLoadingError: () => void;
  onChangeProcessState: (
    asset: ItemAsset | undefined, //May be undefined until state is "complete"
    state: ItemAssetServiceState
  ) => void;
}

export const ItemAssetView = ({
  asset: initialAsset,
  fileURL,
  isThumbnail,
  onChangeProcessState,
  onLoadingError,
}: ItemAssetProps) => {
  const [url, setURL] = useState<string | undefined>();

  useEffect(() => {
    const url =
      fileURL ?? (isThumbnail ? initialAsset?.thumbnailURL : initialAsset?.url);
    if (url) {
      setURL(url);
    }
  }, [initialAsset?.url, initialAsset?.thumbnailURL, fileURL, isThumbnail]);

  const onClickImage = () => {};

  return (
    <Content url={url} onClick={onClickImage} onLoadingError={onLoadingError} />
  );
};

interface ContentProps {
  url?: string;
  onLoadingError: () => void;
  onClick: () => void;
}
const Content = ({ url, onLoadingError, onClick }: ContentProps) => {
  if (url) {
    return (
      <ContentImage
        url={url}
        onClick={onClick}
        onLoadingError={onLoadingError}
      />
    );
  } else {
    return <ContentNoImage onClick={onClick} />;
  }
};

interface ContentNoImageProps {
  onClick: () => void;
}
const ContentNoImage = ({ onClick }: ContentNoImageProps) => {
  return (
    <div className={styles.contentNoImage}>
      <div className={styles.buttonAddImage} onClick={() => onClick}>
        Click to upload an image
      </div>
    </div>
  );
};

interface ContentImageProps {
  url: string;
  onLoadingError: () => void;
  onClick: () => void;
}
const ContentImage = ({ url, onLoadingError, onClick }: ContentImageProps) => {
  return (
    <img
      className={styles.assetImage}
      src={url}
      alt="Uploaded"
      onError={onLoadingError}
      onLoad={() => {
        // Release the object URL to free memory when the image is loaded
        URL.revokeObjectURL(url);
      }}
    />
  );
};
