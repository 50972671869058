import {
  Endpoint,
  AppError,
  POST_JSONObjectToEndpoint,
  GET_Endpoint,
  DELETE_Endpoint,
} from "../API/API";

export const maxAssetCount = 8;
export type SaveType = "DRAFT" | "COMMIT";
export type APIItemState = "DRAFT" | "PUBLISHED";

//When saving/uploading userID is ignored by the API and populated based off of the auth token.
interface Item {
  saveType?: SaveType; // Only used when saving.
  itemState?: APIItemState; // Not currently used by the application - "DRAFT" or "PUBLISHED".
  id: string | undefined;
  userID?: string | undefined;
  title: string | undefined;
  description: string | undefined;
  medium: string | undefined;
  imageKey: string;
  thumbnailKey: string;
  assets: ItemAsset[] | undefined;
  edits?: Item;
}

interface ItemAsset {
  key?: string;
  url?: string; //Will be a signed URL only generated on get item requests.
  thumbnailKey?: string;
  thumbnailURL?: string; //Will be a signed URL generated on list item and get item requests.

  fileURL?: string; // When added locally will be used to show image until signed URLs are generated on saving server side.
}

const saveItem = (item: Item, commit: boolean): Promise<Item> => {
  const endpoint = Endpoint.item;
  item.saveType = commit ? "COMMIT" : "DRAFT";

  delete item.itemState;

  let itemToSave = item;

  itemToSave.id = item.id;
  itemToSave.saveType = item.saveType;
  itemToSave = itemWithStrippedAssetURLs(itemToSave);
  return POST_JSONObjectToEndpoint(endpoint, itemToSave)
    .then((json) => {
      const item = json as Item;
      return item;
    })
    .catch((error) => {
      console.log("Item update failed with error - " + error.message);
      throw new AppError("There was a problem saving the item.", error.code);
    });
};

const itemWithStrippedAssetURLs = (item: Item): Item => {
  var newItem = item;
  newItem.assets = newItem.assets?.map((asset) => {
    var newAsset: ItemAsset = {
      key: asset.key,
      thumbnailKey: asset.thumbnailKey,
    };
    return newAsset;
  });
  return newItem;
};

const getItem = async (id: string): Promise<Item> => {
  const endpoint = Endpoint.item;
  const params = { id: id };
  return GET_Endpoint(endpoint, params)
    .then((json) => {
      const item = json as Item;
      return item;
    })
    .catch((error) => {
      console.log("Failed to fetch item with error - " + error.message);
      if (error.statusCode === 404) {
        throw new AppError("Item not found.", 404);
      }
      throw new AppError("There was a problem loading this item", error.code);
    });
};

const deleteItem = (id: string): Promise<void> => {
  const endpoint = Endpoint.item;
  const params = { id: id };
  return DELETE_Endpoint(endpoint, params).catch((error) => {
    throw new AppError("There was a problem deleting this item", error.code);
  });
};

const getCurrentDraft = async (): Promise<Item> => {
  const endpoint = Endpoint.getDraft;
  return GET_Endpoint(endpoint).then((json) => {
    const itemJSON = json.message;
    if (itemJSON) {
      const item: Item = itemJSON;
      return item;
    } else {
      throw new AppError("Could not create draft", 500);
    }
  });
};

interface ListItemsResponse {
  items: Item[];
  endOfList: boolean;
}

const listItems = (
  lastItem: Item | undefined,
  profileHandle?: string
): Promise<ListItemsResponse> => {
  const endpoint = profileHandle ? Endpoint.userItems : Endpoint.items;
  const limit = 20;
  var parameters: any = { limit: limit };
  if (lastItem) {
    parameters.offsetKey = lastItem.id;
  }
  if (profileHandle) {
    parameters.profileHandle = profileHandle;
  }
  return GET_Endpoint(endpoint, parameters)
    .then((json) => {
      const items = json.items;
      if (items.length > 0) {
        const sanitised = sanitisedItems(items);
        return { items: sanitised, endOfList: items.length < limit };
      }
      return { items: [], endOfList: true };
    })
    .catch((error) => {
      console.log("Failed to fetch items with error - " + error.message);
      throw new AppError("There was a problem loading items.", error.code);
    });
};

const sanitisedItems = (items: any[]): Item[] => {
  return items.map((item) => {
    return sanitiseItem(item);
  });
};

const sanitiseItem = (item: any): Item => {
  //No need to anything currently.
  return item;
};

export type { Item, ItemAsset, ListItemsResponse };
export { getCurrentDraft, saveItem, getItem, listItems, deleteItem };
