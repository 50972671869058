import { useEffect, useContext, useState } from 'react';
import { Alert, Grid, Button, Typography } from '@mui/material';
import { TitleBarContext } from '../TitleBar/TitleBar';
import { SignInForm } from '../Authentication/SignInForm';
import { SignUpForm } from '../Authentication/SignUpForm';
import { useLocation } from 'react-router-dom';
import { usePaths } from '../Paths';
import { AppError } from '../API/API';

export const SplashLanding = () => {
	const { isTitleBarHidden, setIsTitleBarHidden } = useContext(TitleBarContext);

	useEffect(() => {
		if (setIsTitleBarHidden) {
			setIsTitleBarHidden(true);
		}
	});

	return (
		<div style={{ display: 'flex', height: '100vh' }}>
			{/* Background container */}
			<div
				style={{
					width: '400px',
					height: '100%',
					backgroundImage: 'url(/SplashBackground1.png)',
					backgroundSize: 'cover',
					backgroundPosition: 'right',
					position: 'fixed',
					top: 0,
					left: 0,
					zIndex: 1,
				}}>
				<Grid
					container
					direction="column"
					alignItems={'flex-end'}
					paddingRight={'40px'}
					paddingTop={'195px'}>
					<Grid item>
						<img width={'120px'} src="/EaselIconFull.svg" alt="EASEL.INK" />
					</Grid>
				</Grid>
			</div>
			<RightContent />
		</div>
	);
};

interface RightContentProps {}
const RightContent = (props: RightContentProps) => {
	const location = useLocation();
	const paths = usePaths();

	const [isNewUser, setIsNewUser] = useState<boolean>(true);

	const [processingMessage, setProcessingMessage] = useState<
		string | undefined
	>();
	const [errorMessage, setErrorMessage] = useState<string | undefined>();

	useEffect(() => {
		const isSignInPage = paths.pathMatches(location.pathname, paths.signIn);
		setIsNewUser(!isSignInPage);
	}, [location, paths.signIn, paths]);

	const handleIsSigningUp = () => {
		clearMessages();
		setProcessingMessage('Creating your account...');
	};

	const handleDidSignUp = () => {
		clearMessages();
		paths.navigateTo(paths.userProfile);
	};

	const handleDidFailSignUp = (error: AppError) => {
		clearMessages();
		setErrorMessage(error.message);
	};

	const handleIsSigningIn = () => {
		clearMessages();
		setProcessingMessage('Signing up...');
	};
	const handleDidSignIn = () => {
		clearMessages();
		paths.navigateTo(paths.userProfile);
	};

	const handleDidFailSignIn = (error: AppError) => {
		clearMessages();
		setErrorMessage(error.message);
	};

	const clearMessages = () => {
		setProcessingMessage(undefined);
		setErrorMessage(undefined);
	};

	const toggleIsNewUser = () => {
		clearMessages();
		if (isNewUser) {
			paths.navigateTo(paths.signIn);
		} else {
			paths.navigateTo(paths.signUp);
		}
	};

	const ProcessingView = () => {
		return <Typography variant="h2">{processingMessage}</Typography>;
	};

	const toggleNewUserButtonText = (): string => {
		return isNewUser
			? 'Already have an account? Sign in'
			: "Don't have an account? Sign up";
	};

	return (
		<div
			style={{
				position: 'absolute',
				top: 0,
				left: '400px',
				width: '100%',
				height: '100%',
				zIndex: 2,
				paddingTop: '200px',
			}}>
			<Grid container direction="row" sx={{ height: '100%' }}>
				<Grid
					container
					direction="column"
					minWidth="400px"
					maxWidth="400px"
					height="100%"
					spacing={3}
					padding={1}>
					{processingMessage && (
						<Grid item>
							{' '}
							<ProcessingView />{' '}
						</Grid>
					)}
					{!processingMessage && isNewUser && (
						<Grid item>
							<SignUpForm
								handleIsSigningUp={handleIsSigningUp}
								handleDidSignUp={handleDidSignUp}
								handleSignUpFailed={handleDidFailSignUp}
							/>
						</Grid>
					)}
					{!processingMessage && isNewUser == false && (
						<Grid item>
							<SignInForm
								handleIsSigningIn={handleIsSigningIn}
								handleDidSignIn={handleDidSignIn}
								handleSignInFailed={handleDidFailSignIn}
							/>
						</Grid>
					)}
					{errorMessage && (
						<Grid item>
							<Alert severity="error">{errorMessage}</Alert>
						</Grid>
					)}
					{!processingMessage && (
						<Grid item>
							<Button
								variant="link"
								onClick={toggleIsNewUser}
								sx={{ paddingLeft: '4px' }}>
								{toggleNewUserButtonText()}
							</Button>
						</Grid>
					)}
				</Grid>
			</Grid>
		</div>
	);
};
