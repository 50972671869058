import { useState, useEffect } from 'react';
import { signInUser, validateEmail } from './Auth';
import { ButtonContained } from '../CommonUIComponents';
import { Grid, TextField, Alert, Button } from '@mui/material';
import { AppError } from '../API/API';

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

interface SignInFormProps {
	handleIsSigningIn: () => void;
	handleSignInFailed: (error: AppError) => void;
	handleDidSignIn: () => void;
}

export const SignInForm = (props: SignInFormProps) => {
	const [email, setEmail] = useState<string | undefined>();
	const [password, setPassword] = useState<string | undefined>();
	const [errorMessage, setErrorMessage] = useState<string | undefined>();
	const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
	const [isSigningIn, setIsSigninIn] = useState<boolean>(false);

	useEffect(() => {
		validateInputs();
	}, [email, password]);

	const onEmailChange = (event: ChangeEvent) => {
		setEmail(event.target.value);
	};

	const onPasswordChange = (event: ChangeEvent) => {
		setPassword(event.target.value);
	};

	const signIn = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (email && password) {
			props.handleIsSigningIn();
			setIsSigninIn(true);
			event.preventDefault();
			signInUser(email, password)
				.then(() => {
					props.handleDidSignIn();
				})
				.catch((error) => {
					setIsSigninIn(false);
					props.handleSignInFailed(error);
					setErrorMessage(error.message);
					setSubmitEnabled(false);
				});
		}
	};

	const validateInputs = () => {
		if (email && password && password.length > 0 && validateEmail(email)) {
			setSubmitEnabled(true);
		} else {
			setSubmitEnabled(false);
		}
	};

	return (
		<Grid container direction={'column'} rowSpacing={1} alignItems={'left'}>
			<Grid item width={340}>
				<TextField
					label="Email"
					type="email"
					name="signInEmail"
					disabled={isSigningIn}
					fullWidth
					placeholder="Email"
					onChange={onEmailChange}></TextField>
			</Grid>
			<Grid item width={340}>
				<TextField
					label="Password"
					type="password"
					name="signInPassword"
					disabled={isSigningIn}
					fullWidth
					placeholder="Password"
					onChange={onPasswordChange}></TextField>
			</Grid>
			{errorMessage !== undefined ? (
				<Grid item width={340}>
					<Alert severity="error">{errorMessage}</Alert>
				</Grid>
			) : null}
			<Grid item>
				<Button
					variant="outlined"
					disabled={!submitEnabled || isSigningIn}
					onClick={signIn}>
					Sign in
				</Button>
			</Grid>
		</Grid>
	);
};
