import FileResizer from "react-image-file-resizer";
import {
  fetchPresignedUploadURL,
  uploadFileToAWSWithPresignedURL,
} from "../S3/S3Utility";
import { ItemAsset } from "../DataProviders/ItemAPI";

//TODO: put this in it's own file.
export class ItemAssetServiceError extends Error {
  message: string;
  statusCode: number;

  constructor(message: string, statusCode: number) {
    super(message);
    this.message = message;
    this.name = "HttpError";
    this.statusCode = statusCode;
    Object.setPrototypeOf(this, ItemAssetServiceError.prototype); // for extending a built-in class
  }
}

export type ItemAssetServiceState =
  | "isResizing"
  | "isFetchingPresignedURLs"
  | "isUploading"
  | "isComplete"
  | "isError";

export const useItemAssetService = () => {
  const mainImageLargestDimension: number = 3000;
  const thumbnailImageLargestDimension: number = 500;

  const processImageFile = async (
    file: File,
    onChangeState: (state: ItemAssetServiceState) => void
  ): Promise<ItemAsset> => {
    // Resize image to max size and thumbnail size.
    onChangeState("isResizing");
    const resizeResults = await Promise.all([
      resizeImageFile(
        { width: mainImageLargestDimension, height: mainImageLargestDimension },
        file
      ),
      resizeImageFile(
        {
          width: thumbnailImageLargestDimension,
          height: thumbnailImageLargestDimension,
        },
        file
      ),
    ]);
    const resizedImage = resizeResults[0];
    const resizedThumbnail = resizeResults[1];

    // Get presigned URLs for uploading images.
    onChangeState("isFetchingPresignedURLs");
    const urls = await fetchPresignedUploadURL();

    // Upload the images.
    onChangeState("isUploading");
    const imageUploadProcess = uploadFileToAWSWithPresignedURL(
      resizedImage,
      urls.main.url
    );
    const thumbnailUploadProcess = uploadFileToAWSWithPresignedURL(
      resizedThumbnail,
      urls.thumbnail.url
    );
    await Promise.all([imageUploadProcess, thumbnailUploadProcess]);

    // Return the keys for the images.
    const asset: ItemAsset = {
      key: urls.main.key,
      thumbnailKey: urls.thumbnail.key,
    };
    onChangeState("isComplete");
    return asset;
  };

  const resizeImageFile = async (
    size: { width: number; height: number },
    file: File
  ): Promise<File> => {
    return new Promise((resolve) => {
      resizedImage(size, file, async (value) => {
        const base64 = await fetch(value);
        const blob = await base64.blob();
        const newFile = new File([blob], file.name, {
          type: blob.type,
          lastModified: new Date().getTime(),
        });
        resolve(newFile);
      });
    });
  };

  const resizedImage = (
    size: { width: number; height: number },
    imageFile: Blob,
    callback: (value: any) => void
  ) => {
    try {
      FileResizer.imageFileResizer(
        imageFile,
        size.width,
        size.height,
        "JPEG",
        100,
        0,
        callback
      );
    } catch (err) {
      throw new ItemAssetServiceError(
        "Something went wrong when resizing this image.",
        400
      );
    }
  };

  return { processImageFile } as const;
};
