import {
  httpClient,
  Endpoint,
  endpointURL,
  AppError,
  POST_JSONObjectToEndpoint,
  GET_Endpoint,
} from "../API/API";
import { ItemAsset } from "./ItemAPI";

export { getUserProfile, updateUserProfile, validateProfileHandle };
export type { UserProfile };

interface UserProfile {
  displayName: string;
  profileHandle: string;
  //Only populated by the server when retrieving a UserProfile to display.
  asset?: ItemAsset;
  aboutMe: string;
}

const updateUserProfile = (userProfile: UserProfile): Promise<void> => {
  const endpoint = Endpoint.userProfile;
  return POST_JSONObjectToEndpoint(endpoint, userProfile)
    .then((json) => {
      console.log("Updated successfully");
    })
    .catch((error) => {
      console.log("Profile update failed with error - " + error.message);
      throw new AppError(
        "There was a problem retrieving the user profile.",
        error.statusCode
      );
    });
};

const getUserProfile = (): Promise<UserProfile> => {
  const endpoint = Endpoint.userProfile;
  return GET_Endpoint(endpoint, null)
    .then((json) => {
      const profile = json as UserProfile;
      return profile;
    })
    .catch((error) => {
      throw new AppError(error.message, error.statusCode);
    });
};

const validateProfileHandle = (handle: string): boolean => {
  const regex = /^[a-zA-Z0-9_-]*$/;
  return regex.test(handle) && handle.length > 2 && handle.length < 13;
};
