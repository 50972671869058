import { Item } from "../../../DataProviders/ItemAPI";
import { Gallery } from "./Gallery";
import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
interface GalleryContextType {
  gallery: Gallery;
  addItem: (item: Item) => void;
  removeItem: (item: Item) => void;
  toggleItemMembership: (item: Item) => void;
  moveItem: (fromIndex: number, toIndex: number) => void;
  setTitle: (title: string) => void;
  setDescription: (title: string) => void;
}

const GalleryContext = createContext<GalleryContextType | undefined>(undefined);

interface GalleryProviderProps {
  children: ReactNode;
  initialGallery: Gallery;
}

const GalleryProvider = ({
  children,
  initialGallery,
}: GalleryProviderProps) => {
  const [items, setItems] = useState<Item[]>([]);
  const [gallery, setGallery] = useState<Gallery>(initialGallery);

  useEffect(() => {
    setGallery((prevGallery) => {
      if (prevGallery) {
        return { ...prevGallery, items: items };
      }
      return prevGallery; // or create a new gallery object if needed
    });
  }, [items]);

  const addItem = (item: Item) => {
    setItems((prevItems) => [item, ...prevItems]);
  };
  const removeItem = (item: Item) => {
    setItems((prevItems) => {
      return prevItems.filter((i) => {
        return i !== item;
      });
    });
  };

  const toggleItemMembership = (item: Item) => {
    setItems((prevItems) => {
      const index = prevItems.indexOf(item);
      if (index !== -1) {
        // If the item exists, remove it
        return prevItems.filter((i) => i !== item);
      } else {
        // If the item does not exist, add it
        return [item, ...prevItems];
      }
    });
  };

  const moveItem = (fromIndex: number, toIndex: number) => {
    setItems((prevItems) => {
      const item = prevItems[fromIndex];
      const newItems = [...prevItems];
      newItems.splice(fromIndex, 1); // Remove item from the current position
      newItems.splice(toIndex, 0, item); // Insert item at the new position
      return newItems;
    });
  };

  const setTitle = (title: string) => {
    setGallery((prevGallery) => ({ ...prevGallery, title }));
  };

  const setDescription = (description: string) => {
    setGallery((prevGallery) => ({ ...prevGallery, description }));
  };

  return (
    <GalleryContext.Provider
      value={{
        gallery,
        addItem,
        removeItem,
        toggleItemMembership,
        moveItem,
        setTitle,
        setDescription,
      }}
    >
      {children}
    </GalleryContext.Provider>
  );
};

function useGallery() {
  const context = useContext(GalleryContext);
  if (!context) {
    throw new Error("useGallery must be used within an GalleryProvider");
  }
  return context;
}
export { GalleryProvider, useGallery };
