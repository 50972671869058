import styles from "./EditItemToolbar.module.css";
import { useItem } from "./ItemProvider";
import { useCallback, useEffect, useState } from "react";
import { usePaths } from "../../../Paths";
import { ModalSimpleConfirmation } from "../../../CommonComponents/Modal/modal-simple-comfirmation";
export type ToolbarAction = "delete" | "discardChanges" | "save" | "saveDraft";

interface EditItemToolbarProps {
  onAction: (action: ToolbarAction) => void;
}

export const EditItemToolbar = (props: EditItemToolbarProps) => {
  const { hasEdits, commit, revertChanges, validationErrors } = useItem();

  const handleAction = (action: ToolbarAction) => {
    switch (action) {
      case "discardChanges":
        revertChanges();
        break;
      case "save":
        commit();
        break;
    }
  };

  return (
    <div className={styles.toolbar}>
      <LeadingControls onAction={handleAction} />
      <TrailingControls anyEdits={hasEdits} onAction={handleAction} />
    </div>
  );
};

interface TrailingControlsProps {
  anyEdits: boolean;
  onAction: (action: ToolbarAction) => void;
}

const TrailingControls = ({ anyEdits, onAction }: TrailingControlsProps) => {
  const onClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    action: ToolbarAction
  ) => {
    event.preventDefault();
    onAction(action);
  };

  const actions = useCallback((): ToolbarAction[] => {
    var allActions: ToolbarAction[] = ["save", "discardChanges"];
    if (!anyEdits) {
      allActions = []; // There is nothing to do currently without changes. //allActions.filter((action) => action !== "discardChanges" && action !== "saveDraft");
    }
    return allActions;
  }, [anyEdits]);

  return (
    <div className={styles.contentGroup}>
      {actions().includes("discardChanges") && (
        <button
          className="toolbarButton"
          onClick={(event) => onClick(event, "discardChanges")}
        >
          Discard changes
        </button>
      )}
      {actions().includes("saveDraft") && (
        <button
          type="submit"
          className="toolbarButton"
          onClick={(event) => onClick(event, "save")}
        >
          Save draft
        </button>
      )}
      {actions().includes("save") && (
        <button
          type="submit"
          className="toolbarButtonCTA"
          onClick={(event) => onClick(event, "save")}
        >
          Publish
        </button>
      )}
    </div>
  );
};

interface LeadingControlProps {
  onAction: (action: ToolbarAction) => void;
}
const LeadingControls = (props: LeadingControlProps) => {
  const { deleteItem, state: itemProviderState } = useItem();
  const [isConfirmingDelete, setIsConfirmingDelete] = useState<boolean>(false);
  const paths = usePaths();
  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsConfirmingDelete(true);
  };

  const performDeletion = () => {
    setIsConfirmingDelete(false);
    deleteItem();
  };

  useEffect(() => {
    if (itemProviderState === "itemDeleted") {
      paths.navigateTo(paths.items);
    }
  }, [itemProviderState, paths]);

  if (itemProviderState === "isDeleting") {
    return (
      <div className={styles.contentGroup}>
        <div className={styles.deleting}>Deleting...</div>
      </div>
    );
  }

  const actions = (): ToolbarAction[] => {
    return ["delete"];
  };
  return (
    <div className={styles.contentGroup}>
      {actions().includes("delete") && (
        <button className="toolbarButton" onClick={onClick}>
          Delete
        </button>
      )}
      <ModalSimpleConfirmation
        iconSrc="/assets/icons/Trash.svg"
        isOpen={isConfirmingDelete}
        title="Delete item"
        body="Are you sure you want to delete this item?"
        confirm="Delete"
        onConfirm={performDeletion}
        onDismiss={() => setIsConfirmingDelete(false)}
      />
    </div>
  );
};
