import { useItem } from "../ItemProvider";
import styles from "./EditItemSidebar.module.css";
import {
  TextField,
  TextArea,
} from "../../../../CommonComponents/TextFields/TextFields";

interface EditItemSidebarProps {}

export const EditItemSidebar = (props: EditItemSidebarProps) => {
  const { item, setTitle, setDescription, setMedium, validationErrors } =
    useItem();

  const onChangeTitle = (text: string) => {
    setTitle(text);
  };

  const onChangeDescription = (text: string) => {
    setDescription(text);
  };

  const onChangeMedium = (text: string) => {
    setMedium(text);
  };

  return (
    <div className={styles.sidebarContainer}>
      <div className={styles.sidebarContent}>
        <TextField
          title="Title"
          error={validationErrors?.filter((e) => e === "titleRequired")[0]}
          placeholder="Give your work a name"
          text={item.title ?? ""}
          onChange={onChangeTitle}
        />
        <TextArea
          title="Description"
          placeholder="Describe this work"
          text={item.description ?? ""}
          onChange={onChangeDescription}
        />
        <TextField
          title="Medium"
          placeholder="e.g. 'Oil on canvas'"
          text={item.medium ?? ""}
          onChange={onChangeMedium}
        />
      </div>
    </div>
  );
};
