import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
  ReactNode,
} from "react";
import { Item } from "../DataProviders/ItemAPI";

interface ListState {
  data: Item[]; // Replace `any` with your item type
}

export const AssetListContext = createContext<{
  listState: ListState;
  setListState: React.Dispatch<React.SetStateAction<ListState>>;
}>({
  listState: { data: [] },
  setListState: () => {},
});

export const AssetListProvider = (props: { children: ReactNode }) => {
  const [listState, setListState] = useState<ListState>({
    data: [],
  });

  return (
    <AssetListContext.Provider value={{ listState, setListState }}>
      {props.children}
    </AssetListContext.Provider>
  );
};

//When deleting an item and dropping back to the list, this is a convenience function to
//remove it from the list rather than refecting everything.
export const removeItemFromAssetList = (
  itemID: string,
  array: Item[]
): Item[] => {
  return array.filter((item) => item.id !== itemID);
};
