import {
	retrieveAccessToken,
	refreshAccessToken,
} from '../Authentication/Auth';

enum Environment {
	development,
	staging,
	production,
}

export class AppError extends Error {
	message: string;
	statusCode: number;
	constructor(message: string, statusCode: number) {
		super(message);
		this.message = message;
		this.name = 'HttpError';
		this.statusCode = statusCode;
		Object.setPrototypeOf(this, AppError.prototype); // for extending a built-in class
	}
}

const environmentString = process.env.REACT_APP_ENVIRONMENT!;
const environment: Environment =
	Environment[environmentString as keyof typeof Environment];

export enum Endpoint {
	signIn = 'SignIn',
	signUp = 'SignUp',
	signUpConfirm = 'SignUpConfirm',
	signedUploadURL = 'SignedUploadURL',
	userProfile = 'UserProfile',
	item = 'Item',
	getDraft = 'Item/draft',
	items = 'Items',
	userItems = 'UserItems',
}

const baseURLAdmin = 'https://k7sinlydmd.execute-api.eu-north-1.amazonaws.com/';
const baseURLBrowse =
	'https://zx6oqjua41.execute-api.eu-north-1.amazonaws.com/';

const baseURLForEndpoint = (endpoint: Endpoint): string => {
	switch (endpoint) {
		case Endpoint.userItems:
			return baseURLBrowse;
		default:
			return baseURLAdmin;
	}
};

export const endpointURL = (endpoint: Endpoint): string => {
	const stage = process.env.REACT_APP_AWS_API_STAGE;
	const url = baseURLForEndpoint(endpoint) + (stage ?? 'Beta');
	return url + '/' + endpoint;
};

export const endpointURLWithQueryParameters = (
	endpoint: string,
	params: any | null
): string => {
	if (params) {
		var paramString: string = '';
		(Object.keys(params) as (keyof typeof params)[]).forEach(
			(key, index, array) => {
				const value = params[key];
				if (value !== undefined) {
					paramString =
						paramString +
						String(key) +
						'=' +
						encodeURIComponent(params[key]) +
						'&';
				}
			}
		);
		paramString = paramString.slice(0, -1);
		return endpoint + '?' + paramString;
	}
	return endpoint;
};

export const POST_JSONObjectToEndpoint = async (
	endpoint: Endpoint,
	bodyJSONObject: any
): Promise<any> => {
	const bodyString = JSON.stringify(bodyJSONObject);
	return POST_JSONStringToEndpoint(endpoint, bodyString);
};

export const POST_JSONStringToEndpoint = async (
	endpoint: Endpoint,
	bodyJSONString: any
): Promise<any> => {
	const url = endpointURL(endpoint);
	const options: RequestInit = {
		body: bodyJSONString,
	};
	return httpClient(url, options);
};

export const GET_Endpoint = async (
	endpoint: Endpoint,
	queryParameters?: any | null
): Promise<any> => {
	const url = endpointURL(endpoint);
	const endpointWithParameters = endpointURLWithQueryParameters(
		url,
		queryParameters
	);
	const options: RequestInit = {
		method: 'GET',
	};
	return httpClient(endpointWithParameters, options);
};

export const DELETE_Endpoint = async (
	endpoint: Endpoint,
	queryParameters: any | null
): Promise<any> => {
	const url = endpointURL(endpoint);
	const endpointWithParameters = endpointURLWithQueryParameters(
		url,
		queryParameters
	);
	const options: RequestInit = {
		method: 'DELETE',
	};
	return httpClient(endpointWithParameters, options);
};

export const httpClient = async (
	url: string,
	options: RequestInit = {}
): Promise<any> => {
	const headers = new Headers();

	headers.append('Accept', '*/*');
	headers.append('Host', '1cpizg5mue.execute-api.eu-north-1.amazonaws.com');
	const token = retrieveAccessToken();
	if (token) {
		headers.append('Authorization', 'Bearer ' + token);
	}

	var requestOptions = {
		method: options.method ?? 'POST',
		headers: headers,
		body: options.body,
	};

	try {
		const response = await fetch(url, requestOptions);
		if (!response.ok) {
			if (response.status == 401) {
				const success = await refreshAccessToken();
				if (success) {
					//Make the api call again
					return httpClient(url, options);
				} else {
					return Promise.reject(new AppError('Authentication failed', 401));
				}
			} else {
				throw new AppError(
					`HTTP error with status ${response.status}`,
					response.status
				);
			}
		} else {
			const contentLength = response.headers.get('Content-Length');
			if (contentLength && parseInt(contentLength) > 0) {
				return await response.json();
			} else {
				return {};
			}
		}
	} catch (error: unknown) {
		throw error;
	}
};
