import { useState, useEffect, useCallback } from "react";

import styles from "./EditItem.module.css";
import { TitleBarContext } from "../../../TitleBar/TitleBar";
import { useParams } from "react-router-dom";
import { EditItemToolbar, ToolbarAction } from "./EditItemToolbar";
import { EditItemSidebar } from "./EditItemSidebar/EditItemSidebar";
import { ItemProvider, useItem } from "./ItemProvider";
import { ItemAssetServiceState } from "../../../Hooks/useItemAssetService";
import { Item } from "../../../DataProviders/ItemAPI";
import { ItemAssetCollectionView } from "./AssetItemCollectionView/ItemAssetCollectionView";
import { getCurrentDraft, getItem } from "../../../DataProviders/ItemAPI";
import { AppError } from "../../../API/API";
import { AssetToolbarAction } from "./AssetItemCollectionView/ItemAssetCollectionViewToolbar";
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import { usePaths } from "../../../Paths";
import { userIDFromToken } from "../../../Authentication/Auth";

interface EditItemProps {}

export const EditItemV2 = (props: EditItemProps) => {
  const paths = usePaths();
  const [item, setItem] = useState<Item | undefined>();
  const [fetchErrorMessage, setFetchErrorMessage] = useState<
    string | undefined
  >();
  const [saveErrorMessage, setSaveErrorMessage] = useState<
    string | undefined
  >();
  let { itemID } = useParams<{ itemID: string }>();

  const onSubmit = () => {};

  const onToolbarAction = (action: ToolbarAction) => {};

  useEffect(() => {
    if (itemID) {
      getItem(itemID)
        .then((item) => {
          if (item) {
            if (!checkOwnership(item)) {
              paths.navigateTo(paths.viewItem + itemID);
            } else {
              setItem(item);
            }
          }
        })
        .catch((error) => {
          handleFetchError(error);
        });
    } else {
      getCurrentDraft()
        .then((draft) => {
          setItem(draft);
        })
        .catch((error) => {
          handleFetchError(error);
        });
    }
  }, [itemID]);

  const checkOwnership = (item: Item): Boolean => {
    if (item.userID) {
      const userID = userIDFromToken();
      if (userID) {
        //Front end check only, but POST to edit item not owned by authenticated
        //user will fail anyway.
        return userID === item.userID;
      }
    }
    return false;
  };

  const handleFetchError = (err: any) => {
    const error = err as AppError;
    switch (error.statusCode) {
      case 404:
        setFetchErrorMessage("This item could not be found 🙁");
        break;
      default:
        setFetchErrorMessage(
          "There was a problem loading this item, please try again."
        );
        break;
    }
    console.log("Failed to fetch item with error - " + error.message);
  };

  const onUpdateAssetContent = (item: Item) => {};

  if (fetchErrorMessage) {
    return (
      <div className={styles.container}>
        <h2 className={styles.statusMessage}>{fetchErrorMessage}</h2>
      </div>
    );
  }

  if (!item) {
    return (
      <div className={styles.container}>
        <div className={styles.statusMessage}>
          <Dots />
        </div>
      </div>
    );
  }

  return (
    <ItemProvider initialItem={item}>
      <div>
        <form className={styles.container} onSubmit={onSubmit}>
          <EditItemToolbar onAction={onToolbarAction} />
          <div className={styles.innerContent}>
            <EditItemSidebar />
            <MainContent onUpdateAssetContent={onUpdateAssetContent} />
          </div>
        </form>
      </div>
    </ItemProvider>
  );
};

interface MainContentProps {
  onUpdateAssetContent: (item: Item) => void;
}

const MainContent = (props: MainContentProps) => {
  const { addImageFileAsAsset, state: itemProviderState } = useItem();
  const [uploadAssetState, setUploadAssetState] = useState<
    ItemAssetServiceState | undefined
  >();

  const handleAction = (action: AssetToolbarAction, data: any | undefined) => {
    const file = data as File;
    if (file) {
      addImageFileAsAsset(file, (state) => {
        setUploadAssetState(state);
        if (state === "isComplete") {
          setTimeout(() => {
            setUploadAssetState(undefined);
          }, 2000);
        }
      });
    }
  };

  const onSave = () => {
    // Do nothing.
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.mainContent}>
        <ItemAssetCollectionView onUpdateItemAssets={onSave} />
      </div>
    </div>
  );
};

interface ProgressBarProps {
  state: ItemAssetServiceState;
}
const ProgressBar = ({ state }: ProgressBarProps) => {
  const stateDescription = useCallback(() => {
    switch (state) {
      case "isComplete":
        return "Image uploaded";
      case "isError":
        return "Error uploading image";
      case "isFetchingPresignedURLs":
        return "Preparing upload";
      case "isResizing":
        return "Resizing";
      case "isUploading":
        return "Uploading";
    }
  }, [state]);

  const isAnimating = useCallback(() => {
    switch (state) {
      case "isComplete":
      case "isError":
        return false;
      default:
        return true;
    }
  }, [state]);

  return (
    <div className={styles.activityBar}>
      <h6>{stateDescription()}</h6>
      {isAnimating() && <Dots size={20} className={styles.activityIndicator} />}
    </div>
  );
};
