import styles from "./modal.module.css";
import { ModalUnstyled } from "./modal";
import { ReactNode } from "react";

interface ModalSimpleConfirmationProps {
  isOpen: boolean;
  iconSrc?: string;
  iconAlt?: string;
  title: string;
  body: string;
  confirm?: string;
  dismiss?: string;
  onConfirm: () => void;
  onDismiss: () => void;
}
export const ModalSimpleConfirmation = ({
  isOpen,
  iconSrc,
  iconAlt,
  title,
  body,
  confirm = "Okay",
  dismiss = "Cancel",
  onConfirm,
  onDismiss,
}: ModalSimpleConfirmationProps) => {
  return (
    <ModalUnstyled isOpen={isOpen} onDismiss={onDismiss}>
      <div className={styles.innerContainer}>
        <div className={styles.icon}>
          {iconSrc && (
            <img
              src={iconSrc}
              alt={iconAlt ?? "Confirm"}
              width={20}
              height={20}
            />
          )}
        </div>
        <h4 className={styles.title}>{title}</h4>
        <h5 className={styles.body}>{body}</h5>
        <div className={styles.buttonContainer}>
          <button
            className={`buttonCTA ${styles.buttonFlexGrow}`}
            onClick={onDismiss}
          >
            {dismiss}
          </button>
          <button
            className={`button ${styles.buttonFlexGrow}`}
            onClick={onConfirm}
          >
            {confirm}
          </button>
        </div>
      </div>
    </ModalUnstyled>
  );
};
