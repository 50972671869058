import { useItem } from "../ItemProvider";
import { ItemAssetView } from "../ItemAssetView";
import { ItemAsset } from "../../../../DataProviders/ItemAPI";
import { ItemAssetServiceState } from "../../../../Hooks/useItemAssetService";
import { useEffect, useState } from "react";
import { stat } from "fs";
import { useCallback } from "react";
import styles from "./ItemAssetCollectionView.module.css";
import { ModalSimpleConfirmation } from "../../../../CommonComponents/Modal/modal-simple-comfirmation";
import { SingleAsset } from "./ItemAssetSingleAsset";
import { ImportImageControl } from "../../../../CommonComponents/ImportImageControl";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggingStyle,
  OnDragEndResponder,
  DropResult,
  DroppableProps,
} from "react-beautiful-dnd";

interface ItemAssetCollectionViewProps {
  onUpdateItemAssets: () => void;
}

export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

export const ItemAssetCollectionView = ({
  onUpdateItemAssets,
}: ItemAssetCollectionViewProps) => {
  const {
    item,
    setAsset,
    setAssetOrder,
    addImageFileAsAsset,
    removeAsset,
    state: itemContextState,
  } = useItem();
  const [mainAsset, setMainAsset] = useState<ItemAsset | undefined>();
  const [assets, setAssets] = useState<ItemAsset[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [assetToDelete, setAssetToDelete] = useState<ItemAsset | undefined>();

  type ProcessingAsset = { index: number; state: ItemAssetServiceState };

  const [processingAsset, setProcessingAsset] = useState<
    ProcessingAsset | undefined
  >();

  useEffect(() => {
    const isLoading =
      itemContextState === "isSavingCommit" ||
      itemContextState === "isSavingDraft";
    setIsLoading(isLoading);
  }, [setIsLoading, itemContextState]);

  useEffect(() => {
    if (item.assets && item.assets.length > 0) {
      setMainAsset(item.assets[0]);
      setAssets(item.assets.slice(1));
    } else {
      setMainAsset(undefined);
      setAssets([]);
    }
  }, [item.assets]);

  const handleAssetStateChange = useCallback(
    (
      asset: ItemAsset | undefined,
      state: ItemAssetServiceState,
      index: number | undefined
    ) => {
      // if (asset && state === "isComplete") {
      //   setAsset(asset, index);
      //   onUpdateItemAssets();
      // }
      // console.log("State = " + state);
    },
    [onUpdateItemAssets, setAsset]
  );

  useEffect(() => {
    setProcessingAsset(undefined);
  }, [item, setProcessingAsset]);

  const performAssetDeletion = () => {
    if (assetToDelete) {
      removeAsset(assetToDelete);
      setAssetToDelete(undefined);
    }
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reordered = reorder(
      assets,
      result.source.index,
      result.destination.index
    );
    setAssets(reordered);
    if (mainAsset) {
      const allAssets: ItemAsset[] = [mainAsset, ...reordered];
      setAssetOrder(allAssets);
    }
  };

  const reorder = (list: ItemAsset[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleMainAssetFileSet = (file: File) => {
    addImageFileAsAsset(
      file,
      (state) => {
        setProcessingAsset({ index: 0, state });
        console.log("State is " + state);
      },
      0
    );
  };

  const handleSubAssetFileSet = (file: File, index: number) => {
    addImageFileAsAsset(
      file,
      (state) => {
        // Offset the index to account for the main asset.
        const offsetIndex = index + 1;
        setProcessingAsset({ index: offsetIndex, state });
        console.log("State is " + state + " for index " + offsetIndex);
      },
      index + 1
    );
  };

  const shouldShowSupplimentaryImagesTooltip =
    mainAsset !== undefined && assets.length < 6;

  return (
    <div className={styles.container}>
      <MainImage
        asset={mainAsset}
        processingState={
          processingAsset?.index === 0 ? processingAsset.state : undefined
        }
        onImageSet={handleMainAssetFileSet}
        onDelete={() => setAssetToDelete(mainAsset)}
      />
      {shouldShowSupplimentaryImagesTooltip && (
        <h4 className={styles.addExtraImagesTooltip}>
          Add extra images, like work-in-progress, or close-ups.
        </h4>
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              className={
                snapshot.isDraggingOver
                  ? styles.accessoryImagesContainerDragging
                  : styles.accessoryImagesContainer
              }
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {assets.map((asset: ItemAsset, index: number) => (
                <Draggable
                  key={asset.key}
                  draggableId={asset.key ?? ""}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      className={styles.item}
                      key={asset.key}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <SingleAsset
                        asset={asset}
                        fileURL={undefined}
                        isThumbnail={true}
                        processState={
                          processingAsset?.index === index + 1
                            ? processingAsset.state
                            : undefined
                        }
                        onChangeProcessState={(asset, state) =>
                          handleAssetStateChange(asset, state, index)
                        }
                        onDelete={() => setAssetToDelete(asset)}
                        onChangeImage={(file) =>
                          handleSubAssetFileSet(file, index)
                        }
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {assets.length < 6 &&
                Array.from({ length: 6 - assets.length }, (_, index) => (
                  <SubItemPlaceholder
                    index={index}
                    processingState={
                      processingAsset?.index === assets.length + index + 1
                        ? processingAsset.state
                        : undefined
                    } //Offset to account for main asset.
                    isClickable={index === 0 && mainAsset !== undefined}
                    onSetFile={(file) => {
                      handleSubAssetFileSet(file, assets.length + index);
                    }}
                  />
                ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
      <ModalSimpleConfirmation
        iconSrc="/assets/icons/Trash.svg"
        isOpen={assetToDelete !== undefined}
        title="Delete image"
        body="Are you sure you want to delete this image?"
        confirm="Delete"
        onConfirm={performAssetDeletion}
        onDismiss={() => setAssetToDelete(undefined)}
      />
    </div>
  );
};

interface MainImageProps {
  asset: ItemAsset | undefined;
  processingState: ItemAssetServiceState | undefined;
  onImageSet: (file: File) => void;
  onDelete: () => void;
}
const MainImage = ({
  asset,
  processingState,
  onImageSet,
  onDelete,
}: MainImageProps) => {
  const onStateChange = (
    asset: ItemAsset | undefined,
    state: ItemAssetServiceState
  ) => {};
  const [fileURL, setFileURL] = useState<string | undefined>();
  const onClickDelete = () => {
    onDelete();
  };

  useEffect(() => {
    if (asset) {
      if (fileURL) {
        URL.revokeObjectURL(fileURL);
        setFileURL(undefined);
      }
    }
  }, [asset, fileURL]);

  const stateString = useCallback(() => {
    if (!processingState) {
      return undefined;
    }
    switch (processingState) {
      case "isComplete":
        return "Finalizing";
      case "isFetchingPresignedURLs":
        return "Preparing";
      case "isResizing":
        return "Processing";
      case "isUploading":
        return "Uploading";
    }
  }, [processingState]);

  const onSetFile = (file: File) => {
    const url = URL.createObjectURL(file);
    setFileURL(url);
    onImageSet(file);
  };

  if (processingState !== undefined) {
    return (
      <div className={styles.mainImageContainerLoading}>
        <SingleAsset
          asset={undefined}
          fileURL={fileURL}
          isThumbnail={false}
          processState={processingState}
          onChangeProcessState={onStateChange}
          onDelete={onClickDelete}
          onChangeImage={onSetFile}
        />
        <div className={styles.mainAssetProcessingMessageContainer}>
          <div className={styles.message}>
            <img
              className={styles.subAssetPlaceholderProcessingIcon}
              src="/assets/icons/Icon-loading.svg"
              alt="Uploading"
            />
            {stateString && <h3>{stateString()}</h3>}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.mainImageContainer}>
      {asset ? (
        <SingleAsset
          asset={asset}
          fileURL={fileURL}
          isThumbnail={false}
          processState={processingState}
          onChangeProcessState={onStateChange}
          onDelete={onClickDelete}
          onChangeImage={onSetFile}
        />
      ) : (
        <ImportImageControl
          className={styles.mainAssetPlaceholder}
          maximumImageSizeInKB={5000}
          onImageSet={onSetFile}
        >
          <img src="/Plus.svg" alt="Add new" />
          <h3>Add an image of your work</h3>
        </ImportImageControl>
      )}
    </div>
  );
};

interface SubItemPlaceholderProps {
  index: number;
  isClickable: boolean;
  processingState: ItemAssetServiceState | undefined;
  onSetFile: (file: File) => void;
}

const SubItemPlaceholder = ({
  index,
  isClickable,
  processingState,
  onSetFile,
}: SubItemPlaceholderProps) => {
  const canInteract = isClickable && processingState === undefined;

  const isProcessing =
    isClickable && // This is the only placeholder that can be clicked and represents the asset being processed.
    processingState !== undefined;
  if (canInteract) {
    return (
      <ImportImageControl
        className={styles.subAssetPlaceholderClickable}
        maximumImageSizeInKB={5000}
        onImageSet={onSetFile}
      >
        <img src="/Plus.svg" alt="Add new" />
      </ImportImageControl>
    );
  }
  return (
    <div className={styles.subAssetPlaceholder} key={index}>
      {isProcessing && (
        <img
          className={styles.subAssetPlaceholderProcessingIcon}
          src="/assets/icons/Icon-loading.svg"
          alt="Uploading"
        />
      )}
    </div>
  );
};
