import styles from "./ViewItem.module.css";
import { usePaths } from "../../../Paths";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { ItemAsset, getItem } from "../../../DataProviders/ItemAPI";
import { useState } from "react";
import { Item } from "../../../DataProviders/ItemAPI";
import { ItemProvider } from "../EditItem/ItemProvider";
import { AppError } from "../../../API/API";
import { userIDFromToken } from "../../../Authentication/Auth";
import {
  OwnerToolbarAction,
  ViewItemOwnerToolbar,
} from "./ViewItemOwnerToolbar";

interface ViewItemInterface {}

export const ViewItemV2 = (props: ViewItemInterface) => {
  const paths = usePaths();
  let [item, setItem] = useState<Item | undefined>();
  let { itemID } = useParams<{ itemID: string }>();
  const [isOwner, setIsOwner] = useState<Boolean>(false);
  let [fetchError, setFetchError] = useState<string | undefined>();

  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [isImageLoading, setImageIsLoading] = useState<Boolean>(true);

  useEffect(() => {
    if (itemID) {
      getItem(itemID)
        .then((item) => {
          if (item) {
            setItem(item);
            setIsOwner(checkOwnership(item));
            if (item.assets?.length === 0) {
              setFetchError("It looks like there are not images to see here.");
            }
          }
        })
        .catch((error) => {
          setFetchError(error.message);
        });
    }
  }, [itemID, setFetchError]);

  const checkOwnership = (item: Item): Boolean => {
    if (item.userID) {
      const userID = userIDFromToken();
      return userID === item.userID;
    }
    return false;
  };

  if (fetchError) {
    return (
      <div className={styles.container}>
        <h2 className={styles.statusMessage}>{fetchError}</h2>
      </div>
    );
  }

  const assets = item?.assets;

  if (!assets || !isLoading) {
    return (
      <div className={styles.container}>
        <h2 className={styles.statusMessage}>{fetchError}</h2>
      </div>
    );
  }

  const onImageLoad = () => {
    setImageIsLoading(false);
  };

  const onToolbarAction = (action: OwnerToolbarAction) => {
    if (action === "edit") {
      paths.navigateTo(paths.editItem + itemID);
    }
  };

  const mainAsset = assets[0];
  const otherAssets = assets.slice(1);
  return (
    <div className={styles.container}>
      {item && isOwner && (
        <ItemProvider initialItem={item}>
          <ViewItemOwnerToolbar onAction={onToolbarAction} />
        </ItemProvider>
      )}
      <div className={styles.content}>
        {mainAsset.url && (
          <MainImage signedURL={mainAsset.url} onLoad={onImageLoad} />
        )}
        {!isImageLoading && (
          <div className={styles.secondaryContentContainer}>
            <MetaLabels
              title={item?.title}
              description={item?.description}
              medium={item?.medium}
            />
            {otherAssets && <SecondaryImages assets={otherAssets} />}
          </div>
        )}
      </div>
    </div>
  );
};

//
// MAIN IMAGE
//

interface MainImageProps {
  signedURL: string;
  onLoad: () => void;
}

const MainImage = ({ signedURL, onLoad }: MainImageProps) => {
  return (
    <div className={styles.mainImageContainer}>
      <img alt="Main" src={signedURL} onLoad={onLoad} />
    </div>
  );
};

//
// META DATA
//
interface MetaProps {
  title: string | undefined;
  description: string | undefined;
  medium: string | undefined;
}
const MetaLabels = ({ title, description, medium }: MetaProps) => {
  return (
    <div className={styles.metaContainer}>
      <h1>{title || "Default Title"}</h1>
      <h4>{description || "Default Description"}</h4>
      <h6>{medium || "Default Medium"}</h6>
    </div>
  );
};

//
// SECONDAY IMAGES
//

interface SecondaryImagesProps {
  assets: ItemAsset[];
}

const SecondaryImages = ({ assets }: SecondaryImagesProps) => {
  return (
    <div className={styles.secondaryImagesContainer}>
      {assets.map((asset, index) => (
        <img key={index} src={asset.url} alt={`Thumbnail ${index + 1}`} />
      ))}
    </div>
  );
};
