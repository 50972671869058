import { useEffect, useState, useRef } from "react";
import { Grid, useTheme, Button } from "@mui/material";
import { DropDownMenu, DropDownActionItem } from "./DropDownMenu";
import { signOutUser } from "../Authentication/Auth";
import { useNavigate } from "react-router-dom";

export const UserAdminNavBarMenuButton = (props: {}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    // Add a click event listener to the document to handle clicks outside the dropdown
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const dropDownItems = (): DropDownActionItem[] => {
    return [
      { id: "items", title: "My items", navigationDestination: "/items" },
      { id: "addItem", title: "Add new item", navigationDestination: "/edit" },
      { id: "separator", title: "separator", isSeparator: true },
      {
        id: "myProfile",
        title: "My profile",
        navigationDestination: "/userProfile",
      },
      { id: "signOut", title: "Sign out", navigationDestination: "/signIn" },
    ];
  };

  const itemSelection = (item: DropDownActionItem) => {
    toggleDropdown();
    if (item.id == "signOut") {
      signOutUser();
    }
    if (item.navigationDestination) {
      navigate(item.navigationDestination);
    }
  };

  useEffect(() => {
    // Add a click event listener to the document to handle clicks outside the dropdown
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent={"center"}
      paddingX={1}
      sx={{
        height: "50px",
        cursor: "pointer",
        "&:hover": {
          background: theme.palette.BACKGROUND_HIGHLIGHTED?.main,
        },
      }}
      onClick={(e) => {
        e.stopPropagation(); // Prevent the click event from propagating to the document
        toggleDropdown();
      }}
    >
      <img
        width="20"
        src={showDropdown ? "/ChevronUpThin.svg" : "/ChevronDownThin.svg"}
        alt="Drop down menu"
      />
      {showDropdown && (
        <div ref={dropdownRef}>
          <DropDownMenu
            items={dropDownItems()}
            dropDownActive={true}
            toggleDropDown={toggleDropdown}
            itemSelection={itemSelection}
          />
        </div>
      )}
    </Grid>
  );
};
