import { useRef, useState, ReactNode } from "react";

import Button from "@mui/material/Button";
import { styled, makeStyles } from "@mui/material/styles";

interface ImageUploadControlProps {
  maximumImageSizeInKB: number;
  onImageSet: (file: File) => void;
  className?: string | undefined;
  children: ReactNode;
}

export const ImportImageControl = (props: ImageUploadControlProps) => {
  const inputFile = useRef<HTMLInputElement | null>(null);

  const addImageClicked = () => {
    inputFile?.current?.click();
  };

  const validateImageSelected = (file: File) => {
    if (file === undefined) {
      return;
    }
    const fileSizeKB = file.size / 1024;
    let tooBig = fileSizeKB > props.maximumImageSizeInKB;
    if (tooBig) {
      console.log("Image is too big to be uploaded.");
    } else {
      setImageFile(file);
    }
  };

  const setImageFile = (file: File) => {
    props.onImageSet(file);
  };

  return (
    <div className={props.className} onClick={addImageClicked}>
      {props.children}
      <input
        type="file"
        accept="image/png, image/jpeg"
        id="file"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={(event) => {
          event.stopPropagation();
          event.preventDefault();
          if (event.target.files) {
            var file = event.target.files[0];
            validateImageSelected(file);
          }
        }}
      />
    </div>
  );
};
