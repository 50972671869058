import { Grid } from '@mui/material';
import { ButtonContained } from '../CommonUIComponents';
import { signOutUser } from '../Authentication/Auth';
import { usePaths } from '../Paths';

export const UserHome = (props: {}) => {
	const paths = usePaths();

	const signOut = () => {
		signOutUser();
		paths.navigateTo(paths.signIn);
	};

	return (
		<Grid
			container
			direction={'column'}
			alignItems={'center'}
			spacing={1}
			padding={1}>
			<Grid item>
				<ButtonContained onClick={signOut}>Sign out</ButtonContained>
			</Grid>
		</Grid>
	);
};
