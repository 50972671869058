import "./App.css";
import { AppTitleBar } from "./TitleBar/TitleBar";
import { ThemeProvider, Box, ListItem } from "@mui/material";
import { MainTheme } from "./Theme";
import { Routes, Route, Navigate } from "react-router-dom";
import { SignUpConfirmPage } from "./Authentication/SignUpConfirmPage";
import { UserHome } from "./Home/UserHome";
import { UserProfilePage } from "./Pages/UserProfile/UserProfilePage";
import { EditItemV2 } from "./Pages/Items/EditItem/EditItemV2";
import { ListItems } from "./Pages/Items/ListItems/ListItems";
import { AssetListProvider } from "./CommonComponents/AssetListContext";
import { isUserAuthenticated } from "./Authentication/Auth";
import { SplashLanding } from "./Pages/SplashLanding";
import { usePaths } from "./Paths";
import { ViewItemV2 } from "./Pages/Items/ViewItem/ViewItemV2";
import { GalleryCollection } from "./Pages/Items/Collections/GalleryCollection";

function App() {
  const paths = usePaths();
  return (
    <AssetListProvider>
      <ThemeProvider theme={MainTheme}>
        <AppTitleBar>
          <Routes>
            <Route path={paths.home} element={<SplashLanding />} />
            <Route
              path={paths.userProfileWithHandle + ":profileHandle"}
              element={<ListItems />}
            />
            <Route
              path={paths.userProfile}
              element={<AuthenticatedElement element={<UserProfilePage />} />}
            />
            <Route path={paths.viewItem + ":itemID"} element={<ViewItemV2 />} />
            <Route
              path={paths.user}
              element={<AuthenticatedElement element={<UserHome />} />}
            />
            <Route path={paths.signUp} element={<SplashLanding />} />
            <Route path={paths.signIn} element={<SplashLanding />} />

            <Route
              path={paths.confirmEmail + ":email"}
              element={<SignUpConfirmPage />}
            />

            <Route
              path={paths.createItem}
              element={<AuthenticatedElement element={<EditItemV2 />} />}
            />
            <Route path={paths.editItem + ":itemID"} element={<EditItemV2 />} />
            <Route
              path={paths.items}
              element={<AuthenticatedElement element={<ListItems />} />}
            />
            <Route
              path={paths.editGallery + ":galleryID"}
              element={<AuthenticatedElement element={<GalleryCollection />} />}
            />
            <Route
              path={paths.editGallery}
              element={<AuthenticatedElement element={<GalleryCollection />} />}
            />
          </Routes>
        </AppTitleBar>
      </ThemeProvider>
    </AssetListProvider>
  );
}

export default App;

const AuthenticatedElement = (props: { element: JSX.Element }) => {
  return isUserAuthenticated() ? props.element : <Navigate to="/" replace />;
};
