import { Endpoint, httpClient, AppError, GET_Endpoint } from '../API/API';

import { resizeImageFile } from '../CommonComponents/ThumbnailService';

interface PresignedURL {
	main: {
		url: string;
		key: string;
	};
	thumbnail: {
		url: string;
		key: string;
	};
}

export const fetchPresignedUploadURL = async (): Promise<PresignedURL> => {
	return GET_Endpoint(Endpoint.signedUploadURL, null)
		.then((json) => {
			let url = json as PresignedURL;
			return url;
		})
		.catch((error) => {
			throw new AppError('Failed to upload image', 500);
		});
};

//Returns URL file was uploaded to.
export interface ImageUploadConfiguration {
	resizedImageSize: { width: number; height: number };
	thumbnailImageSize: { width: number; height: number };
}

export const DefaultImageUploadConfiguration = (): ImageUploadConfiguration => {
	return {
		resizedImageSize: { width: 3000, height: 3000 },
		thumbnailImageSize: { width: 600, height: 600 },
	};
};

export interface ImageUploadResult {
	imageKey: string;
	thumbnailKey: string;
}

export const uploadImage = async (
	file: File,
	configuration: ImageUploadConfiguration
): Promise<ImageUploadResult> => {
	let presignedURL = await fetchPresignedUploadURL();

	let main = presignedURL.main.url;
	let thumbnail = presignedURL.thumbnail.url;

	const imageResizeProcess = resizeImageFile(
		configuration.resizedImageSize,
		file
	);
	const thumbnailResizeProcess = resizeImageFile(
		configuration.thumbnailImageSize,
		file
	);

	return Promise.all([imageResizeProcess, thumbnailResizeProcess]).then(
		([mainFile, thumbnailFile]) => {
			const imageUploadProcess = uploadFileToAWSWithPresignedURL(
				mainFile,
				presignedURL.main.url
			);
			const thumbnailUploadProcess = uploadFileToAWSWithPresignedURL(
				thumbnailFile,
				presignedURL.thumbnail.url
			);
			return Promise.all([imageUploadProcess, thumbnailUploadProcess]).then(
				() => {
					const result: ImageUploadResult = {
						imageKey: presignedURL.main.key,
						thumbnailKey: presignedURL.thumbnail.key,
					};
					return result;
				}
			);
		}
	);
};

export const uploadFileToAWSWithPresignedURL = async (
	file: File,
	presignedUrl: string
): Promise<void> => {
	try {
		const response = await fetch(presignedUrl, {
			method: 'PUT',
			body: file,
		});
		if (response.ok) {
			return;
		} else {
			throw new AppError('There was a problem uploading your image.', 400);
			console.error('Failed to upload file to AWS');
		}
	} catch (error) {
		console.error('Error occurred during file upload:', error);
		throw new AppError(
			'An error occurred during file upload.  Please check your network.',
			500
		);
	}
};
