import { ValidationError } from "../../Pages/Items/EditItem/ItemProvider";
import styles from "./TextFields.module.css";
type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

type Variant = "small" | "large";

interface TextFieldProps {
  title?: string;
  text: string;
  placeholder: string;
  variant?: Variant;
  error?: ValidationError;
  onChange: (text: string) => void;
}

export const TextField = ({
  title,
  text,
  placeholder,
  variant = "large",
  error,
  onChange,
}: TextFieldProps) => {
  const onChangeText = (event: ChangeEvent) => {
    onChange(event.target.value);
  };

  const isError = error !== undefined;

  const className =
    variant === "small" ? "text-field-minimal" : "text-field-minimal-large";

  return (
    <div className={isError ? styles.errorElement : styles.container}>
      {title && <h2>{title}</h2>}
      <input
        type="text"
        className={className}
        placeholder={placeholder}
        onChange={onChangeText}
        value={text}
      />
    </div>
  );
};

interface TextAreaProps {
  title?: string;
  text: string;
  placeholder: string;
  onChange: (text: string) => void;
  className?: string;
}

export const TextArea = (props: TextAreaProps) => {
  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onChange(event.target.value);
  };

  return (
    <div>
      {props.title && <h2>{props.title}</h2>}
      <textarea
        className={`text-area-minimal ${props.className}`}
        value={props.text}
        onChange={onChange}
      />
    </div>
  );
};
