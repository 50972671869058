import { ItemAsset } from "../../../DataProviders/ItemAPI";
import { Item } from "../../../DataProviders/ItemAPI";

// This doesn't make sense since no new asset will have the same key as an
// existing one.
// export const updateAssetOnItem = (item: Item, asset: ItemAsset) => {
//   // Check if exists and replace it.
//   //If it doesn't, add it to the end of the assets.
//   const newAsset = asset;
//   if (!(item.assets && item.assets.length > 0)) {
//     item.assets = [newAsset];
//   } else {
//     const newAssets: ItemAsset[] = item.assets.map((asset) =>
//       asset.key === newAsset.key ? newAsset : asset
//     );
//     item.assets = newAssets;
//   }
// };

export const setAssetOnItem = (
  item: Item,
  newAsset: ItemAsset,
  index?: number | undefined
) => {
  const itemToEdit = item;
  if (!(itemToEdit.assets && itemToEdit.assets.length > 0)) {
    itemToEdit.assets = [newAsset];
  } else if (index === undefined || index === itemToEdit.assets.length) {
    itemToEdit.assets.push(newAsset);
  } else if (index !== undefined) {
    itemToEdit.assets[index] = newAsset;
  }
};

export const removeAssetFromItem = (item: Item, asset: ItemAsset) => {
  if (item.edits) {
    console.log("assets = " + item.edits.assets?.length);
    item.edits.assets = item.edits.assets?.filter(
      (candidate) => asset.key !== candidate.key
    );
  } else {
    item.assets = item.assets?.filter(
      (candidate) => asset.key !== candidate.key
    );
  }
};

function deepEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) {
    return true;
  }
  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}

function isObject(object: any) {
  return object != null && typeof object === "object";
}

export const itemsAreEqual = (item1: Item, item2: Item): boolean => {
  return deepEqual(item1, item2);
};
