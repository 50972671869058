import { AppError } from "../../../API/API";
import { Item } from "../../../DataProviders/ItemAPI";
import { AssetGrid } from "../ListItems/AssetGrid";
import styles from "./GalleryCollection.module.css";
import { TitleBarContext } from "../../../TitleBar/TitleBar";
import { UserAdminNavBarMenuButton } from "../../../TitleBar/NavBarControls";
import { useContext, useEffect } from "react";
import { GalleryProvider, useGallery } from "./GalleryProvider";
import { useState } from "react";
import { Gallery } from "./Gallery";
import {
  TextField,
  TextArea,
} from "../../../CommonComponents/TextFields/TextFields";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DroppableProps,
} from "react-beautiful-dnd";

const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

interface GalleryCollectionProps {}

export const GalleryCollection = (props: GalleryCollectionProps) => {
  const { setTitleBarTitle, setTrailingTitleBarComponents } =
    useContext(TitleBarContext);
  const [gallery, setGallery] = useState<Gallery | undefined>();

  useEffect(() => {
    const g: Gallery = {
      title: "",
      description: "",
      items: [],
    };
    setGallery(g);
    setTrailingTitleBarComponents(<UserAdminNavBarMenuButton />);
  }, [setTrailingTitleBarComponents]);

  console.log("Rendering gallery");
  if (!gallery) {
    return <div>Error</div>;
  }
  return (
    <GalleryProvider initialGallery={gallery}>
      <MainContent />
    </GalleryProvider>
  );
};

interface MainContentProps {}

const MainContent = (props: MainContentProps) => {
  const { gallery, addItem, removeItem, moveItem, toggleItemMembership } =
    useGallery();
  const [isShowingInstructions, setIsShowingInstructions] =
    useState<boolean>(false);

  const onAddNewItem = () => {};

  const onViewItem = (item: Item) => {
    toggleItemMembership(item);
  };

  useEffect(() => {
    setIsShowingInstructions(gallery.items.length < 3);
  }, [gallery.items]);

  const selectedIDs = gallery.items.map((item) => item.id ?? "");

  const onError = () => {};
  return (
    <div className={styles.container}>
      <CollectionContainer gallery={gallery} />
      <div className={styles.mainContainer}>
        {isShowingInstructions && (
          <div className={styles.instructions}>
            Select items to add to your collection
          </div>
        )}
        <AssetGrid
          profileHandle={undefined}
          isLoading={false}
          selectedItemIDs={selectedIDs}
          addNewItem={onAddNewItem}
          viewItem={onViewItem}
          onError={onError}
        />
      </div>
    </div>
  );
};

interface CollectionContainerProps {
  gallery: Gallery;
}

const CollectionContainer = ({ gallery }: CollectionContainerProps) => {
  const { removeItem, setTitle, setDescription } = useGallery();
  const [isShowingDescription, setIsShowingDescription] =
    useState<boolean>(false);

  const assetForItem = (item: Item) => {
    if (item.assets) {
      return item.assets[0];
    }
  };

  const onImageLoad = (index: number) => {};

  const reorder = (
    list: typeof gallery.items,
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const [items, setItems] = useState(gallery.items);

  useEffect(() => {
    setItems(gallery.items);
    if (gallery.description) {
      setIsShowingDescription(true);
    }
  }, [gallery, items.length]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newItems);
    // Optionally, you can update the state on your server or elsewhere
  };

  const onClickDeleteAsset = (item: Item) => {
    removeItem(item);
  };

  return (
    <div className={styles.collectionContainer}>
      <div className={styles.collectionMetaContainer}>
        <TextField
          text={gallery.title}
          placeholder={"Name your collection"}
          onChange={setTitle}
          variant="large"
        />
        {isShowingDescription && (
          <TextArea
            text={gallery.description}
            placeholder={"Name your collection"}
            onChange={setDescription}
            className={styles.descriptionField}
          />
        )}
        {!isShowingDescription && (
          <button
            className={styles.descriptionButton}
            onClick={() => setIsShowingDescription(true)}
          >
            Add desctiption
          </button>
        )}
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={styles.collectionImageContainer}
            >
              {items.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id || "default_id"}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={styles.item}
                    >
                      <img
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,

                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                        className={styles.itemContent}
                        src={assetForItem(item)?.thumbnailURL ?? ""}
                        alt={item.title}
                        onLoad={() => onImageLoad(index)}
                      />
                      <div className={styles.itemControls}>
                        <button
                          className={styles.itemControlButton}
                          onClick={() => onClickDeleteAsset(item)}
                        >
                          <img
                            src="/assets/icons/Trash.svg"
                            alt="Delete"
                            width={20}
                            height={20}
                          />
                        </button>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {Array.from({ length: 8 - items.length }).map((_, index) => (
                <div
                  key={`placeholder-${index}`}
                  className={styles.placeholderItem}
                  style={{ opacity: 1 - index * 0.2 }}
                ></div>
                // <span>{index + items.length}</span>
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </div>
  );
};
