import { ItemAssetView } from "../ItemAssetView";
import { ItemAsset } from "../../../../DataProviders/ItemAPI";
import { ItemAssetServiceState } from "../../../../Hooks/useItemAssetService";
import styles from "./ItemAssetCollectionView.module.css";
import { useState } from "react";
import { ImportImageControl } from "../../../../CommonComponents/ImportImageControl";

export interface SingleAssetProps {
  asset: ItemAsset | undefined;
  fileURL: string | undefined; //A temporary url for a file being uploaded.
  isThumbnail: boolean;
  processState: ItemAssetServiceState | undefined;
  onChangeProcessState: (
    asset: ItemAsset | undefined, //May be undefined until state is "complete"
    state: ItemAssetServiceState
  ) => void;
  onDelete: () => void;
  onChangeImage: (file: File) => void;
}
export const SingleAsset = ({
  asset,
  fileURL,
  isThumbnail,
  processState,
  onChangeProcessState,
  onDelete,
  onChangeImage,
}: SingleAssetProps) => {
  const [isLoadingError, setIsLoadingError] = useState<boolean>(false);

  return (
    <div className={styles.singleAssetContainer}>
      {processState !== undefined && <div>processing</div>}

      {isLoadingError && (
        <div className={styles.singleAssetError}>LOADING ERROR</div>
      )}
      {!isLoadingError && processState === undefined && (
        <div className={styles.imageClip}>
          <ItemAssetView
            key={asset?.key}
            asset={asset}
            fileURL={fileURL}
            isThumbnail={isThumbnail}
            onChangeProcessState={onChangeProcessState}
            onLoadingError={() => setIsLoadingError(true)}
          />
        </div>
      )}

      <div className={styles.singleAssetToolbar}>
        <button
          className={styles.toolbarIconButton}
          onClick={(event) => {
            event.preventDefault();
            onDelete();
          }}
        >
          <img
            src="/assets/icons/Trash.svg"
            alt="Delete"
            width={20}
            height={20}
          />
        </button>
        <ImportImageControl
          className={styles.toolbarIconButton}
          maximumImageSizeInKB={5000}
          onImageSet={onChangeImage}
        >
          <img
            src="/assets/icons/AddPhoto-alt.svg"
            alt="Delete"
            width={20}
            height={20}
          />
        </ImportImageControl>
      </div>
    </div>
  );
};
