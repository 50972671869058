import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Grid, Typography, TextField, Alert } from '@mui/material';
import { validateEmail } from './Auth';
import { confirmUser, signInAfterConfirmation } from './Auth';
import { ButtonContained } from '../CommonUIComponents';

interface SignUpConfirmFormProps {
	handleIsConfirming: () => void;
	handleConfirmFailed: () => void;
	handleDidConfirm: (didSignIn: boolean) => void;
}

export const SignUpConfirmForm = (props: SignUpConfirmFormProps) => {
	const [verificationCode, setVerificationCode] = useState<
		string | undefined
	>();
	const [isConfirming, setIsConfirming] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string | undefined>();
	const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);

	const { email } = useParams();

	const onChangeVerificationCode = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setVerificationCode(event.target.value);
	};

	const validateInputs = (): boolean => {
		if (email) {
			if (!validateEmail) {
				return false;
			}
			if (verificationCode) {
				if (verificationCode.length == 0) {
					return false;
				}
				return true;
			}
		}
		return false;
	};

	const confirm = () => {
		if (email && verificationCode) {
			setIsConfirming(true);
			props.handleIsConfirming();
			confirmUser(email, verificationCode)
				.then(() => {
					signInAfterConfirmation().then((didSignIn) => {
						props.handleDidConfirm(didSignIn);
					});
				})
				.catch((error) => {
					props.handleConfirmFailed();
					setIsConfirming(false);
					if (error.statusCode == 422) {
						setErrorMessage(
							'Please check your confirmation code and try again.'
						);
					} else {
						setErrorMessage(error.message);
					}
				});
		}
	};

	return (
		<Grid
			container
			rowSpacing={2}
			alignItems={'center'}
			direction={'column'}
			padding={2}>
			<Grid item width={340}>
				<Typography variant="h3">
					A verification code has been sent to your email address, please enter
					it below to confirm your account.
				</Typography>
			</Grid>
			<Grid item container direction={'column'} spacing={1}>
				<Grid item width={340}>
					<TextField
						fullWidth
						label="Email"
						placeholder="email"
						disabled={isConfirming}
						value={email || ''}
					/>
				</Grid>
				<Grid item width={340}>
					<TextField
						fullWidth
						label="Verification code"
						placeholder="Verfication code"
						disabled={isConfirming}
						onChange={onChangeVerificationCode}
					/>
				</Grid>
			</Grid>
			{errorMessage !== undefined ? (
				<Grid item width={340}>
					<Alert severity="error">{errorMessage}</Alert>
				</Grid>
			) : null}
			<Grid item>
				<ButtonContained
					disabled={!validateInputs() || isConfirming}
					onClick={confirm}>
					{isConfirming ? 'Confirming...' : 'Confirm'}
				</ButtonContained>
			</Grid>
		</Grid>
	);
};
