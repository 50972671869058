import { useCallback } from "react";
import styles from "./ViewItemOwnerToolbar.module.css";
import { useItem } from "../EditItem/ItemProvider";
import { usePaths } from "../../../Paths";
import { useState, useEffect } from "react";
import { ModalSimpleConfirmation } from "../../../CommonComponents/Modal/modal-simple-comfirmation";

export type OwnerToolbarAction = "delete" | "edit";

interface OwnerToolbarProps {
  onAction: (action: OwnerToolbarAction) => void;
}

export const ViewItemOwnerToolbar = ({ onAction }: OwnerToolbarProps) => {
  const handleAction = (action: OwnerToolbarAction) => {
    onAction(action);
  };

  return (
    <div className={styles.toolbar}>
      <LeadingControls onAction={handleAction} />
      <TrailingControls onAction={handleAction} />
    </div>
  );
};

interface TrailingControlsProps {
  onAction: (action: OwnerToolbarAction) => void;
}

const TrailingControls = ({ onAction }: TrailingControlsProps) => {
  const onClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    action: OwnerToolbarAction
  ) => {
    event.preventDefault();
    onAction(action);
  };

  const actions = (): OwnerToolbarAction[] => {
    return ["delete", "edit"];
  };

  return (
    <div className={styles.contentGroup}>
      {actions().includes("edit") && (
        <button
          className="toolbarButton"
          onClick={(event) => onClick(event, "edit")}
        >
          Edit
        </button>
      )}
    </div>
  );
};

interface LeadingControlProps {
  onAction: (action: OwnerToolbarAction) => void;
}
const LeadingControls = (props: LeadingControlProps) => {
  const { deleteItem, state: itemProviderState } = useItem();
  const [isConfirmingDelete, setIsConfirmingDelete] = useState<boolean>(false);
  const paths = usePaths();
  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsConfirmingDelete(true);
  };

  const performDeletion = () => {
    setIsConfirmingDelete(false);
    deleteItem();
  };

  useEffect(() => {
    if (itemProviderState === "itemDeleted") {
      paths.navigateTo(paths.items);
    }
  }, [itemProviderState, paths]);

  if (itemProviderState === "isDeleting") {
    return (
      <div className={styles.contentGroup}>
        <div className={styles.deleting}>Deleting...</div>
      </div>
    );
  }

  const actions = (): OwnerToolbarAction[] => {
    return ["delete"];
  };
  return (
    <div className={styles.contentGroup}>
      {actions().includes("delete") && (
        <button className="toolbarButton" onClick={onClick}>
          Delete
        </button>
      )}
      <ModalSimpleConfirmation
        iconSrc="/assets/icons/Trash.svg"
        isOpen={isConfirmingDelete}
        title="Delete item"
        body="Are you sure you want to delete this item?"
        confirm="Delete"
        onConfirm={performDeletion}
        onDismiss={() => setIsConfirmingDelete(false)}
      />
    </div>
  );
};
