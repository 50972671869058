import * as React from "react";
import clsx from "clsx";
import { styled, css } from "@mui/system";
import { Modal as BaseModal } from "@mui/base/Modal";
import { ReactNode } from "react";
import styles from "./modal.module.css";
import { useEffect, useState } from "react";

interface ModalUnstyledProps {
  isOpen: boolean;
  onDismiss: () => void;
  children: ReactNode;
}
export const ModalUnstyled = ({
  isOpen,
  onDismiss,
  children,
}: ModalUnstyledProps) => {
  return (
    <Modal
      open={isOpen}
      onClose={onDismiss}
      slots={{ backdrop: StyledBackdrop }}
    >
      <div className={styles.container}>{children}</div>
    </Modal>
  );
};

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "base-Backdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 300ms ease-out, visibility 0ms linear 300ms; // Transition for fade effect
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.05);
  -webkit-tap-highlight-color: transparent;
`;
