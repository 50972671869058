import { Button, Grid, TextField, Alert } from '@mui/material';
import { AppError } from '../API/API';
import { useState, useEffect } from 'react';
import { signUpUser, validateEmail, validatePassword } from './Auth';
import { usePaths } from '../Paths';

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

export { SignUpForm };

interface SignUpFormProps {
	handleIsSigningUp: () => void;
	handleSignUpFailed: (error: AppError) => void;
	handleDidSignUp: () => void;
}

const SignUpForm = (props: SignUpFormProps) => {
	const [email, setEmail] = useState<string | undefined>();
	const [password, setPassword] = useState<string | undefined>();
	const [confirmPassword, setConfirmPassword] = useState<string | undefined>();
	const [isSigningUp, setIsSigningUp] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string | undefined>();
	const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);

	const paths = usePaths();

	const requireConfirmPassword = false;

	useEffect(() => {
		validateInputs();
		setErrorMessage(undefined);
	}, [email, password, confirmPassword]);

	const onEmailChange = (event: ChangeEvent) => {
		setEmail(event.target.value);
	};

	const onPasswordChange = (event: ChangeEvent) => {
		setPassword(event.target.value);
	};

	const onConfirmPasswordChange = (event: ChangeEvent) => {
		setConfirmPassword(event.target.value);
	};

	const signUp = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (email && password) {
			setIsSigningUp(true);
			props.handleIsSigningUp();
			event.preventDefault();
			signUpUser(email, password)
				.then(() => {
					setIsSigningUp(false);
					props.handleDidSignUp();
					paths.navigateTo(paths.confirmEmail + email);
				})
				.catch((error) => {
					setIsSigningUp(false);
					setErrorMessage(error.message);
					setSubmitEnabled(false);
					props.handleSignUpFailed(error);
				});
		}
	};

	const validateInputs = () => {
		if (
			email &&
			validateEmail(email) &&
			password &&
			validatePassword(password) &&
			(!requireConfirmPassword ||
				(requireConfirmPassword &&
					confirmPassword &&
					password === confirmPassword))
		) {
			setSubmitEnabled(true);
		} else {
			setSubmitEnabled(false);
		}
	};

	const passwordHelperText = (): string | undefined => {
		if (password && password?.length > 0) {
			return 'Must be at least 8 characters in length, contain at least 1 uppercase character, and one special character.';
		}
	};

	return (
		<Grid container rowSpacing={1} alignItems={'left'} direction={'column'}>
			<Grid item width={340}>
				<TextField
					label="Email"
					type="email"
					name="signUpEmail"
					className="form-text-field"
					fullWidth
					disabled={isSigningUp}
					placeholder="Email"
					onChange={onEmailChange}></TextField>
			</Grid>
			<Grid item width={340}>
				<TextField
					label="Password"
					type="password"
					name="signUpPassword"
					className="form-text-field"
					fullWidth
					disabled={isSigningUp}
					placeholder="Password"
					onChange={onPasswordChange}></TextField>
			</Grid>
			{requireConfirmPassword && (
				<Grid item width={340}>
					<TextField
						label="Confirm password"
						type="password"
						name="signUpConfirmPassword"
						className="form-text-field"
						helperText={passwordHelperText()}
						fullWidth
						disabled={isSigningUp}
						placeholder="Confirm password"
						onChange={onConfirmPasswordChange}></TextField>
				</Grid>
			)}
			{errorMessage !== undefined ? (
				<Grid item width={340}>
					<Alert severity="error">
						There was a problem signing up with this email & password. Please
						try again.
					</Alert>
				</Grid>
			) : null}
			<Grid item>
				<Button
					variant="outlined"
					disabled={!submitEnabled || isSigningUp}
					onClick={signUp}>
					{isSigningUp ? 'Signing up...' : 'Sign up'}
				</Button>
			</Grid>
		</Grid>
	);
};

const ProgressView = (props: {}) => {
	return (
		<Grid
			container
			rowSpacing={1}
			alignItems={'center'}
			direction={'column'}
			padding={1}>
			<Grid item>Signing up</Grid>
		</Grid>
	);
};
