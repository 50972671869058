import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Grid, Button, Box, ListItemProps } from "@mui/material";
import styles from "./ListItems.module.css";
import { AppError } from "../../../API/API";
import { useTheme } from "@mui/material/styles";

import { TitleBarContext } from "../../../TitleBar/TitleBar";
import { UserAdminNavBarMenuButton } from "../../../TitleBar/NavBarControls";
import { Item } from "../../../DataProviders/ItemAPI";
import { AssetGrid } from "./AssetGrid";
import { usePaths } from "../../../Paths";
import { Dots } from "react-activity";

interface ListItemsProps {}

export const ListItems = (props: ListItemProps) => {
  const paths = usePaths();
  let { profileHandle } = useParams<{ profileHandle: string }>();

  const {
    setTitleBarTitle,
    setTrailingTitleBarComponents,
    setIsTitleBarHidden,
  } = useContext(TitleBarContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    setTitleBarTitle("Items");
    setIsTitleBarHidden(false);
    setTrailingTitleBarComponents(<UserAdminNavBarMenuButton />);
  }, [setIsTitleBarHidden, setTitleBarTitle, setTrailingTitleBarComponents]);

  const addNewItem = () => {
    paths.navigateTo(paths.createItem);
  };

  const viewItem = (item: Item) => {
    paths.navigateTo(paths.viewItem + item.id);
  };

  const handleAssetGridError = (error: AppError) => {
    setErrorMessage(error.message);
  };

  return (
    <div className={styles.container}>
      <AssetGrid
        profileHandle={profileHandle}
        isLoading={isLoading}
        addNewItem={addNewItem}
        viewItem={viewItem}
        onError={handleAssetGridError}
      />
    </div>
  );
};

interface ProfileInfoAreaProps {}
const ProfileInfoArea = (props: ProfileInfoAreaProps) => {
  return <Box width="300px" height="100px"></Box>;
};

interface NewItemButtonProps {
  onClick: () => void;
}

const NewItemButton = (props: NewItemButtonProps) => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={-1}>
      <Button className="asset-grid-button" onClick={props.onClick}>
        New item
      </Button>
    </Grid>
  );
};
